import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { Request } from 'components/request/Request';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const MyRequestPage: React.FC = () => {
    const { contractNumber } = useParams<{ contractNumber?: string }>();
    const { t } = useTranslation('request');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <Request unpaidBalanceContract={contractNumber || ''} />
        </ContentSection>
    );
};
