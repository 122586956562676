import { UnpaidBalancePageCms } from '@cp-nl/common';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { UnpaidBalanceDetails } from 'components/unpaid-balance-details';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useCmsContent } from 'utils/useCmsContent';

export const UnpaidBalancePage: React.FC = () => {
    const { contractNumber } = useParams<{ contractNumber: string }>();
    const [unpaidBalanceCms] = useCmsContent<UnpaidBalancePageCms>('unpaid-balance-cms');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{unpaidBalanceCms?.headline}</Heading>
            <UnpaidBalanceDetails encryptedContractNumber={contractNumber} />
        </ContentSection>
    );
};
