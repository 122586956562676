import { BankDetails, BankDetailsSectionContentCms } from '@cp-nl/common';
import { DataOverview, DefinitionList, DefinitionListItem, NotificationStatus } from '@cp-shared-7/frontend-ui';
import { Notification } from '@vwfs-bronson/bronson-react';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IbanNotificationType } from '../edit-view/validated-iban-input/types';

export type BankDetailsViewProps = {
    bankDetails?: BankDetails;
    cmsContent?: BankDetailsSectionContentCms;
    onEdit: () => void;
    notificationType: IbanNotificationType;
};

export const BankDetailsView: React.FC<BankDetailsViewProps> = ({
    onEdit,
    cmsContent,
    bankDetails,
    notificationType,
}) => {
    const { t } = useTranslation('my-profile');

    if (!cmsContent) return null;
    if (!bankDetails?.iban) {
        return <NoConnectionNotification />;
    }

    const { notification } = cmsContent;

    const chooseNotificationText = (type: IbanNotificationType) => {
        if (type === 'Success') return notification.success;
        else if (type === 'ManualChange') return notification.manualChange;
        else if (type === '2Factor') return notification.required2Factor.text;
    };

    const bankDetailsItems: DefinitionListItem[] = [
        {
            label: t('bank-details.bank-account-label'),
            value: bankDetails.iban,
            tooltip: t('bank-details.bank-account-tooltip'),
        },
        {
            label: t('bank-details.payment-method'),
            value: bankDetails?.paymentMethod,
        },
    ].filter((item) => item.value);

    return (
        <DataOverview
            buttonLabel={cmsContent?.editButtonLabel}
            buttonProps={{ onClick: (): void => onEdit() }}
            title={t('bank-details.sub-headline')}
        >
            <DefinitionList split={true} list={bankDetailsItems} />
            {notificationType && (
                <Notification
                    status={notificationType === 'Success' ? NotificationStatus.success : NotificationStatus.warning}
                    title={notificationType === '2Factor' ? notification.required2Factor.headline : ''}
                    testId="notification"
                    visible
                    showCloseButton={false}
                >
                    {chooseNotificationText(notificationType)}
                </Notification>
            )}
        </DataOverview>
    );
};
