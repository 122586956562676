import { formatAsCurrency, PayOffProposal, TotalEarlySettlement } from '@cp-nl/common';
import { formatCpDate } from '@cp-shared-7/common-utilities';
import { Notification, NotificationStatus, useAnalyticsPageViewTracker } from '@cp-shared-7/frontend-ui';
import { Button, ButtonContainer, DescriptionListAccordion, Heading, Layout } from '@vwfs-bronson/bronson-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TodaysValidQuoteProps = {
    payOffProposal: PayOffProposal;
    totalEarlySettlementCMS?: TotalEarlySettlement;
    backButtonClick: () => void;
    continueButtonClick: () => void;
};

export const TodaysValidQuote: React.FC<TodaysValidQuoteProps> = ({
    totalEarlySettlementCMS,
    backButtonClick,
    continueButtonClick,
    payOffProposal,
}) => {
    const { t } = useTranslation('total-early-settlement');
    const [isCollapsed, setIsCollapsed] = useState(false);
    useAnalyticsPageViewTracker('earlySettlementDetails', isCollapsed);

    const accordionClickListener = (e: Event) => {
        const element = e.target as Element;
        const collapsible = element.closest('.c-accordion__header');
        setIsCollapsed(!collapsible?.classList.contains('is-active'));
    };

    useEffect(() => {
        window.document.querySelector('.c-accordion__header')?.addEventListener('click', accordionClickListener);
    }, []);

    return (
        <Layout>
            <Layout.Item>
                <Heading level={2}>{totalEarlySettlementCMS?.settlementDetails.headline}</Heading>
                {totalEarlySettlementCMS?.settlementDetails.text}
            </Layout.Item>
            <Layout.Item>
                {payOffProposal.arrearNotification && (
                    <Notification status={NotificationStatus.warning}>
                        {totalEarlySettlementCMS?.settlementDetails.arrearNotification}
                    </Notification>
                )}
            </Layout.Item>
            <Layout.Item>
                <DescriptionListAccordion
                    headerSplit
                    totalLabel={t('calculation-details.calculation-date')}
                    totalValue={formatCpDate(payOffProposal.calculationDate).format('DD MMM YYYY')}
                >
                    <DescriptionListAccordion.Item
                        title={t('calculation-details.headline-expanded')}
                        titleSplit={formatAsCurrency(payOffProposal.payOffAmount)}
                    >
                        {!!payOffProposal.termsInArrears && payOffProposal.termsInArrears.toFixed(2) && (
                            <DescriptionListAccordion.DL label={t('calculation-details.terms-in-arrears')}>
                                {formatAsCurrency(payOffProposal.termsInArrears)}
                            </DescriptionListAccordion.DL>
                        )}
                        {!!payOffProposal.remainingTermsAmount &&
                            payOffProposal.remainingTermsAmount.toFixed(2) !== '0.00' && (
                                <DescriptionListAccordion.DL label={t('calculation-details.remaining-terms-amount')}>
                                    {formatAsCurrency(payOffProposal.remainingTermsAmount)}
                                </DescriptionListAccordion.DL>
                            )}
                        {!!payOffProposal.latePaymentInterest &&
                            payOffProposal.latePaymentInterest.toFixed(2) !== '0.00' && (
                                <DescriptionListAccordion.DL label={t('calculation-details.late-payment-interest')}>
                                    {formatAsCurrency(payOffProposal.latePaymentInterest)}
                                </DescriptionListAccordion.DL>
                            )}
                        {!!payOffProposal.prepayments && payOffProposal.prepayments.toFixed(2) !== '0.00' && (
                            <DescriptionListAccordion.DL label={t('calculation-details.prepayments')}>
                                {formatAsCurrency(-payOffProposal.prepayments)}
                            </DescriptionListAccordion.DL>
                        )}
                        {!!payOffProposal.grossPayOffAmount && payOffProposal.grossPayOffAmount.toFixed(2) !== '0.00' && (
                            <DescriptionListAccordion.DL
                                label={<span style={{ fontWeight: 'bold' }}>{t('calculation-details.subtotal')}</span>}
                            >
                                <span style={{ fontWeight: 'bold' }}>
                                    {formatAsCurrency(payOffProposal.grossPayOffAmount)}
                                </span>
                            </DescriptionListAccordion.DL>
                        )}
                        {!!payOffProposal.interestRestitution &&
                            payOffProposal.interestRestitution.toFixed(2) !== '0.00' && (
                                <DescriptionListAccordion.DL label={t('calculation-details.interest-restitution')}>
                                    {formatAsCurrency(-payOffProposal.interestRestitution)}
                                </DescriptionListAccordion.DL>
                            )}
                        {!!payOffProposal.earlyTerminationCost &&
                            payOffProposal.earlyTerminationCost.toFixed(2) !== '0.00' && (
                                <DescriptionListAccordion.DL label={t('calculation-details.early-termination-cost')}>
                                    {formatAsCurrency(payOffProposal.earlyTerminationCost)}
                                </DescriptionListAccordion.DL>
                            )}
                    </DescriptionListAccordion.Item>
                    {!!payOffProposal.futureTerms && payOffProposal.futureTerms.toFixed(2) !== '0.00' && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            title={t('calculation-details.future-terms')}
                            titleSplit={formatAsCurrency(payOffProposal.futureTerms && -payOffProposal.futureTerms)}
                        ></DescriptionListAccordion.Item>
                    )}
                    <DescriptionListAccordion.Item
                        nonCollapsable
                        title={t('calculation-details.future-pay-off-ammount')}
                        titleSplit={formatAsCurrency(payOffProposal.futurePayoffAmount)}
                    ></DescriptionListAccordion.Item>
                </DescriptionListAccordion>
            </Layout.Item>
            <Layout.Item>
                <ButtonContainer center>
                    <Button secondary onClick={backButtonClick} testId="dashboardButton" type="btn">
                        {t('back-button')}
                    </Button>
                    <Button onClick={continueButtonClick} testId="offlinePaymentButton" type="btn">
                        {t('continue-button')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </Layout>
    );
};
