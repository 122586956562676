import { getNoConnectionNotificationEndpoint, NoConnectionNotification } from '@cp-nl/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<NoConnectionNotification>({
    contentName: 'noConnectionNotification',
    contentEndpoint: getNoConnectionNotificationEndpoint,
});

export default reducer;
export const fetchNoConnectionNotification = fetchContent;
