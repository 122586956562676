import { TFunction } from 'i18next';

export const capitalizeWord = (word: string): string => {
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
};

export const getFuelTypeTranslation = (fuelType: string, t: TFunction): string => {
    let type: string;
    switch (fuelType) {
        case 'diesel':
            type = 'diesel';
            break;
        case 'electric':
            type = 'electric';
            break;
        case 'gasoline':
            type = 'gasoline';
            break;
        case 'hybridDieselElectric':
            type = 'hybrid-diesel-electric';
            break;
        case 'hybridGasolineElectric':
            type = 'hybrid-gasoline-electric';
            break;
        case 'liquidGas':
            type = 'liquid-gas';
            break;
        case 'naturalGas':
            type = 'natural-gas';
            break;
        case 'unknown':
            type = 'unknown';
            break;
        default:
            type = '';
            break;
    }

    return capitalizeWord(!!type ? t(`vehicle-details.fuelType.${type}`) : fuelType);
};

export const getTransmissionTranslation = (transmission: string, t: TFunction): string => {
    const transmissionToLowerCase = transmission.toLowerCase();
    const shouldTranslate = ['automated', 'automatic', 'direct', 'manual', 'sequential', 'unknown'].some(
        (el) => el === transmissionToLowerCase,
    );

    return shouldTranslate ? t(`vehicle-details.transmission.${transmissionToLowerCase}`) : transmission;
};

export const getColorTranslation = (color: string, t: TFunction): string => {
    const colorToLowerCase = color.toLowerCase();
    const shouldTranslate = [
        'beige',
        'black',
        'blue',
        'bronze',
        'brown',
        'burgundy',
        'gold',
        'green',
        'grey',
        'orange',
        'pink',
        'purple',
        'red',
        'silver',
        'transparent',
        'turquoise',
        'violette',
        'white',
        'yellow',
    ].some((el) => el === colorToLowerCase);

    return shouldTranslate ? t(`vehicle-details.color.${colorToLowerCase}`) : color;
};

export const getColorFinishTranslation = (colorFinish: string, t: TFunction) => {
    const colorFinishToLowerCase = colorFinish.toLowerCase();
    const shouldTranslate = ['crystal', 'effect', 'matte', 'metallic', 'pearlescent', 'solid'].some(
        (el) => el === colorFinishToLowerCase,
    );
    return shouldTranslate ? t(`vehicle-details.color-finish.${colorFinishToLowerCase}`) : colorFinish;
};
