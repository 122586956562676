import { useGetContractBasedApiData } from '@cp-shared-7/frontend-integration';
import { ContractDetailsLoadingPlaceholder } from '@cp-shared-7/frontend-ui';
import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { InterveningPartiesDataSelector } from './InterveningPartiesDataSelector';
import { fetchInterveningParties } from './InterveningPartiesSlice';
import { InterveningPartiesUi } from './ui';

const InterveningPartiesWithWrappers = withLoadingAndNoConnectionHandler(InterveningPartiesUi);

type InterveningPartiesProps = {
    contractId: string;
    link: string | undefined;
};

export const InterveningParties: React.FC<InterveningPartiesProps> = ({ contractId, link }) => {
    const { data: interveningParties, isLoading, loadingError } = useGetContractBasedApiData(
        contractId,
        fetchInterveningParties,
        InterveningPartiesDataSelector,
        link,
    );

    return (
        <InterveningPartiesWithWrappers
            isLoading={isLoading}
            hasError={!!loadingError}
            parties={interveningParties}
            loadingPlaceholder={<ContractDetailsLoadingPlaceholder />}
        />
    );
};
