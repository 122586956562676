import { Contract, ContractErrorCode } from '@cp-nl/common';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-7/frontend-integration';
import { useMemo } from 'react';
import { ContractsData } from '../../../../common/build';
import { ContractsDataSelector } from './ContractsDataSelector';
import { fetchContracts } from './ContractsSlice';

type ContractTransformer = (contractsData?: ContractsData) => Contract | undefined;

const getFilterForContractId = (contractId: string, encryptedContractNumber?: boolean): ContractTransformer => {
    return (contractsData) =>
        contractsData &&
        contractsData.contracts?.filter((contract) => {
            const filterVal = encryptedContractNumber ? contract.encryptedContractVersionId : contract.contractNumber;
            return filterVal === contractId;
        })[0];
};

type ConfigProps = {
    encryptedContractNumber?: boolean;
};

/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractId Contract ID to get the contract for.
 */
export function useContract(
    contractId: string,
    config: ConfigProps = {},
): AbstractDataState<Contract | undefined, ContractErrorCode> {
    const { encryptedContractNumber } = config;
    const contractFilter = useMemo(() => getFilterForContractId(contractId, encryptedContractNumber), [
        contractId,
        encryptedContractNumber,
    ]);
    return useGetSimpleApiDataWithTransformer(fetchContracts, ContractsDataSelector, contractFilter);
}
