import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const otherBrandsAvailableModalClosedSlice = createSlice({
    name: 'otherBrandsAvailableModalClosed',
    initialState: { closed: false },
    reducers: {
        setClosed: (state, action: PayloadAction<boolean>) => {
            state.closed = action.payload;
        },
    },
});

export default otherBrandsAvailableModalClosedSlice.reducer;
export const { setClosed } = otherBrandsAvailableModalClosedSlice.actions;
