import * as Yup from 'yup';
import { TFunction } from 'i18next';

const removeSpacesForMaxLength = (phone?: string | null): boolean => {
    return !(phone && phone?.replace(/\s/g, '').length > 15);
};

const isCountryCodeNL = (prefixForPhone?: string): boolean => {
    return prefixForPhone === '31';
};

const isValidPhoneNumberLengthNL = (phone?: string | null, prefixForPhone?: string): boolean => {
    return !(isCountryCodeNL(prefixForPhone) && phone && phone?.replace(/\s/g, '').length !== 9);
};

const isValidMobilePhoneNL = (phone?: string | null, prefixForPhone?: string): boolean => {
    return !(isCountryCodeNL(prefixForPhone) && phone && !phone.startsWith('6'));
};

const isValidMainPhoneNL = (phone?: string | null, prefixForPhone?: string): boolean => {
    return !(isCountryCodeNL(prefixForPhone) && phone && (phone.startsWith('6') || phone.startsWith('0')));
};

const isCountryCodeEmpty = (phone?: string | null, prefixForPhone?: string): boolean => {
    return !(phone && !prefixForPhone);
};

export const validationSchema = (t: TFunction, prefixMobilePhone?: string, prefixMainPhone?: string) => {
    const requiredEmailError = 'validation.email.required';
    const invalidEmailError = 'validation.email.invalid';
    const requiredPhoneError = 'validation.phone.required';
    const invalidPhoneError = 'validation.phone.invalid';
    const invalidMobilePhoneError = 'validation.phone.invalid-mobile';
    const invalidMainPhoneError = 'validation.phone.invalid-main';
    const startWithZeroOrSix = 'validation.phone.start-with-zero-or-six';
    const invalidCountryCodeError = 'validation.country-code.invalid';
    return Yup.object().shape({
        emailAddress: Yup.string().trim().required(t(requiredEmailError)).email(t(invalidEmailError)),
        mobilePhoneCountryCode: Yup.string().required(t(invalidCountryCodeError)),
        mobilePhone: Yup.string()
            .trim()
            .required(t(requiredPhoneError))
            .matches(/^[\d ]*$/, t(invalidMobilePhoneError))
            .test('valid phone number length', t(invalidMobilePhoneError), (mobilePhone) => {
                return isValidPhoneNumberLengthNL(mobilePhone, prefixMobilePhone);
            })
            .test('valid mobile phone format', t(invalidMobilePhoneError), (mobilePhone) => {
                return isValidMobilePhoneNL(mobilePhone, prefixMobilePhone);
            })
            .test('not count spaces for max length', t(invalidMobilePhoneError), (mobilePhone) => {
                return removeSpacesForMaxLength(mobilePhone);
            }),
        mainPhoneCountryCode: Yup.string(),
        mainPhone: Yup.string()
            .trim()
            .matches(/^[\d ]*$/, t(invalidPhoneError))
            .test('valid phone number length', t(invalidMainPhoneError), (mainPhone): boolean => {
                return isValidPhoneNumberLengthNL(mainPhone, prefixMainPhone);
            })
            .test('valid main phone format', t(startWithZeroOrSix), (mainPhone) => {
                return isValidMainPhoneNL(mainPhone, prefixMainPhone);
            })
            .test('country code is empty', t(invalidCountryCodeError), (mainPhone): boolean => {
                return isCountryCodeEmpty(mainPhone, prefixMainPhone);
            })
            .test('not count spaces for max length', t(invalidMainPhoneError), (mobilePhone): boolean => {
                return removeSpacesForMaxLength(mobilePhone);
            }),
    });
};
