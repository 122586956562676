import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import financialDetailsReducer from 'components/contracts/financial-details/FinancialDetailsSlice';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import contractsReducer from '../components/contracts/ContractsSlice';
import interveningPartiesReducer from '../components/contracts/intervening-parties/InterveningPartiesSlice';
import brokerDetailsReducer from '../components/dashboard-marketing-card/BrokerDetailsSlice';
import dashboardMarketingCardReducer from '../components/dashboard-marketing-card/DashboardMarketingCardSlice';
import faqPrivateReducer from '../components/faq/FaqPrivateSlice';
import faqPublicReducer from '../components/faq/FaqPublicSlice';
import iconFooterReducer from '../components/icon-footer/IconFooterSlice';
import landingPageTeaserReducer from '../components/landing-page-teaser/LandingPageTeaserSlice';
import legalFooterReducer from '../components/legal-footer/LegalFooterSlice';
import addressSectionReducer from '../components/my-profile/address-section/AddressSectionSlice';
import contactSectionReducer from '../components/my-profile/contact-section/ContactSectionSlice';
import myProfileReducer from '../components/my-profile/overview/MyProfileSlice';
import navigationBarReducer from '../components/navigation/NavigationBarSlice';
import contractsCountReducer from '../components/navigation/contracts-count/ContractsCountSlice';
import noConnectionNotificationReducer from '../components/notifications/no-connection/NoConnectionNotificationSlice';
import postboxDocumentsReducer from '../components/postbox/PostboxDocumentsSlice';
import registrationEmailReducer from '../components/registration/registration-email/RegistrationEmailSlice';
import myRequestReducer from '../components/request/MyRequestSlice';
import totalEarlySettlementReducer from '../components/total-early-settlement/TotalEarlySettlementContentSlice';
import vehicleImageReducer from 'components/contracts/vehicle-details-and-image/VehicleImageSlice';
import vehicleDetailsReducer from 'components/contracts/vehicle-details-and-image/VehicleDetailsSlice';
import otherBrandsAvailableModalReducer from 'components/other-brands-available-modal/OtherBrandsAvailableModalSlice';
import otherBrandsAvailableModalClosedReducer from 'components/other-brands-available-modal/otherBrandsAvailableModalClosedSlice';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    myProfile: myProfileReducer,
    financialDetails: financialDetailsReducer,
    interveningParties: interveningPartiesReducer,
    contracts: contractsReducer,
    contractsCount: contractsCountReducer,
    postboxDocuments: postboxDocumentsReducer,
    brokerDetails: brokerDetailsReducer,
    otherBrandsAvailableModalClosed: otherBrandsAvailableModalClosedReducer,
    vehicleDetailsAndImage: combineReducers({
        vehicleImages: vehicleImageReducer,
        vehicleDetails: vehicleDetailsReducer,
    }),
    content: combineReducers({
        iconFooter: iconFooterReducer,
        legalFooter: legalFooterReducer,
        landingPageTeaser: landingPageTeaserReducer,
        faqPublic: faqPublicReducer,
        faqPrivate: faqPrivateReducer,
        registrationEmail: registrationEmailReducer,
        navigationBar: navigationBarReducer,
        noConnectionNotification: noConnectionNotificationReducer,
        myRequest: myRequestReducer,
        dashboardMarketingCard: dashboardMarketingCardReducer,
        contactSection: contactSectionReducer,
        addressSection: addressSectionReducer,
        totalEarlySettlement: totalEarlySettlementReducer,
        otherBrandsAvailableModal: otherBrandsAvailableModalReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
