import React from 'react';

import { getDisplayName } from '@cp-shared-7/frontend-ui';

import { withLoadingHandler, withLoadingHandlerProps } from './withLoadingHandler';
import { withNoConnectionHandler, withNoConnectionHandlerProps } from './withNoConnectionHandler';

type combinedProps<TProps> = TProps & withLoadingHandlerProps & withNoConnectionHandlerProps;

export const withLoadingAndNoConnectionHandler = <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
): React.FC<combinedProps<TProps>> => {
    const ComponentWithHocs = withLoadingHandler(withNoConnectionHandler(WrappedComponent));
    ComponentWithHocs.displayName = `withLoadingAndNoConnectionHandler(${getDisplayName(WrappedComponent)})`;
    return ComponentWithHocs;
};
