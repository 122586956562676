import { FaqDto, getFaqPublicEndpoint } from '@cp-nl/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<FaqDto>({
    contentName: 'faq',
    contentEndpoint: getFaqPublicEndpoint,
});

export default reducer;
export const fetchFaq = fetchContent;
