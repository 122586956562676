import { getPostboxDocumentsEndpoint, PostboxDocument } from '@cp-nl/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-7/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-7/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<PostboxDocument[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'postboxDocuments',
    fetchCallback() {
        return CpDataApi.get(getPostboxDocumentsEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const fetchPostboxDocuments = fetchData;
