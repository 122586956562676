import React from 'react';
import { Notification } from '@vwfs-bronson/bronson-react';
import { NotificationProps } from './NotificationProps';

export const CustomErrorNotification: React.FC<NotificationProps> = ({ text, ...notificationProps }) => {
    return (
        <Notification visible showCloseButton={false} {...notificationProps}>
            {text && <span dangerouslySetInnerHTML={{ __html: text }} />}
        </Notification>
    );
};
