import React from 'react';
import { withLoadingHandler, withLoadingHandlerProps } from './withLoadingHandler';
import { withCustomErrorNotification, withCustomErrorNotificationProps } from './withCustomErrorNotification';
import { getDisplayName } from '@cp-shared-7/frontend-ui';

type combinedProps<TProps, TErrorCode extends string> = TProps &
    withLoadingHandlerProps &
    withCustomErrorNotificationProps<TErrorCode>;

export const withLoadingAndCustomErrorNotificationHandler = <TErrorCode extends string>() => <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
): React.FC<combinedProps<TProps, TErrorCode>> => {
    const ComponentWithHandler = withLoadingHandler(withCustomErrorNotification<TErrorCode>()(WrappedComponent));
    ComponentWithHandler.displayName = `withLoadingAndCustomErrorNotificationHandler(${getDisplayName(
        WrappedComponent,
    )})`;
    return ComponentWithHandler;
};
