import { MarketingCardLoadingPlaceholder } from '@cp-shared-7/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useDashboardMarketingCard } from './useDashboardMarketingCard';
import { useMostRecentContract } from './useMostRecentContract';
import { DashboardMarketingCardWithContract } from './with-contract/DashboardMarketingCardWithContract';

const DashboardMarketingCardWithContractHandlers = withLoadingAndNoConnectionHandler(
    DashboardMarketingCardWithContract,
);

export const DashboardMarketingCard: React.FC = () => {
    const { cmsContent: dashboardMarketingCard, isLoading, loadingError } = useDashboardMarketingCard();

    const { data: mostRecentContract, isLoading: contractIsLoading } = useMostRecentContract();

    if (!dashboardMarketingCard) return null;

    return (
        <DashboardMarketingCardWithContractHandlers
            isLoading={isLoading || contractIsLoading}
            dashboardMarketingCard={dashboardMarketingCard}
            mostRecentContract={mostRecentContract}
            hasError={!!loadingError}
            loadingPlaceholder={<MarketingCardLoadingPlaceholder />}
        />
    );
};
