import React from 'react';
import { ValidatedSelectItem, ValidatedSelect } from '@cp-shared-7/frontend-ui';
import { useTranslation } from 'react-i18next';
import { getRequestCategories } from './categories';

type CategorySelectProps = {
    label: string;
    name: string;
    placeholder: string;
    isUnpaidBalance?: boolean;
};

export const CategorySelect: React.FC<CategorySelectProps> = ({ label, name, placeholder, isUnpaidBalance }) => {
    const { t } = useTranslation('request');

    const categoryNumbers: string[] = getRequestCategories();

    const categoriesSelectItems: ValidatedSelectItem[] = categoryNumbers.map((categoryNumber: string) => {
        const categoryText = t(`category.text.${categoryNumber}`);
        return {
            label: categoryText,
            value: categoryNumber,
        };
    });

    return (
        <ValidatedSelect
            label={label}
            name={name}
            selectItems={categoriesSelectItems}
            testId={name}
            placeholder={!isUnpaidBalance ? placeholder : undefined}
        />
    );
};
