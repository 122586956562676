import React from 'react';
import { useNoConnectionNotification } from './useNoConnectionNotification';
import { NotificationStatus } from '@cp-shared-7/frontend-ui';
import { Notification } from '@vwfs-bronson/bronson-react';

export type NoConnectionNotificationProps = {
    testId?: string;
};

export const NoConnectionNotification: React.FC<NoConnectionNotificationProps> = ({ testId }) => {
    const { cmsContent: notificationError } = useNoConnectionNotification();

    return (
        <Notification
            status={NotificationStatus.error}
            title={notificationError?.headline}
            testId={testId}
            visible
            showCloseButton={false}
        >
            {notificationError?.text}
        </Notification>
    );
};
