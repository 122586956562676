type CountryCode = { [key: string]: string[] };

export const countryCodeMapping: CountryCode = {
    "Bahama's": ['1-242', 'BS'],
    Algerije: ['213', 'DZ'],
    'Amerikaans Samoa': ['1-684', 'AS'],
    'Amerikaanse Maagdeneilanden': ['1-340', 'VI'],
    Andorra: ['376', 'AD'],
    Angola: ['244', 'AO'],
    Anguilla: ['1-264', 'AI'],
    Antarctica: ['672', 'AQ'],
    'Antigua en Barbuda': ['1-268', 'AG'],
    Argentinië: ['54', 'AR'],
    Armenië: ['374', 'AM'],
    Aruba: ['297', 'AW'],
    Australië: ['61', 'AU'],
    Azerbeidzjan: ['994', 'AZ'],
    Bahrein: ['973', 'BH'],
    Bangladesh: ['880', 'BD'],
    Barbados: ['1-246', 'BB'],
    België: ['32', 'BE'],
    Belize: ['501', 'BZ'],
    Benin: ['229', 'BJ'],
    Bermuda: ['1-441', 'BM'],
    Bhutan: ['975', 'BT'],
    Bolivia: ['591', 'BO'],
    'Bosnië en Herzegovina': ['387', 'BA'],
    Botswana: ['267', 'BW'],
    Bouveteiland: ['47', 'BV'],
    Brazilië: ['55', 'BR'],
    'Brits Indische Oceaanterritorium': ['246', 'IO'],
    'Britse Maagdeneilanden  ': ['1-284', 'VG'],
    Brunei: ['673', 'BN'],
    Bulgarije: ['359', 'BG'],
    'Burkina Faso': ['226', 'BF'],
    Burundi: ['257', 'BI'],
    Cambodja: ['855', 'KH'],
    Canada: ['1', 'CA'],
    'Centraal-Afrikaanse Republiek': ['236', 'CF'],
    Chili: ['56', 'CL'],
    China: ['86', 'CN'],
    Christmaseiland: ['61', 'CX'],
    Cocoseilanden: ['61', 'CC'],
    Colombia: ['57', 'CO'],
    Comoren: ['269', 'KM'],
    'Congo-Brazzaville': ['242', 'CG'],
    'Congo-Kinshasa': ['243', 'CG'],
    Cookeilanden: ['682', 'CK'],
    'Costa Rica': ['506', 'CR'],
    Cuba: ['53', 'CU'],
    Curaçao: ['599', 'CW'],
    Cyprus: ['357', 'CY'],
    Denemarken: ['45', 'DK'],
    Djibouti: ['253', 'DJ'],
    Dominica: ['1-767', 'DM'],
    'Dominicaanse Republiek (1-809)': ['1-809', 'DO'],
    'Dominicaanse Republiek (1-829)': ['1-829', 'DO'],
    'Dominicaanse Republiek (1-849)': ['1-849', 'DO'],
    Duitsland: ['49', 'DE'],
    Ecuador: ['593', 'EC'],
    Egypte: ['20', 'EG'],
    'El Salvador': ['503', 'SV'],
    'Equatoriaal-Guinea': ['240', 'GQ'],
    Eritrea: ['291', 'ER'],
    Estland: ['372', 'EE'],
    Ethiopië: ['251', 'ET'],
    Faeröer: ['298', 'FO'],
    Falklandeilanden: ['500', 'FK'],
    Fiji: ['679', 'FJ'],
    Filipijnen: ['63', 'PH'],
    Finland: ['358', 'FI'],
    Frankrijk: ['33', 'FR'],
    'Franse Zuidelijke Gebieden': ['590', 'TF'],
    'Frans-Guyana': ['594', 'GF'],
    'Frans-Polynesië': ['689', 'PF'],
    Gabon: ['241', 'GA'],
    Gambia: ['220', 'GM'],
    Georgië: ['995', 'GE'],
    Ghana: ['233', 'GH'],
    Gibraltar: ['350', 'GI'],
    Grenada: ['1-473', 'GD'],
    Griekenland: ['30', 'GR'],
    Groenland: ['299', 'GL'],
    Guadeloupe: ['590', 'GP'],
    Guam: ['1-671', 'GU'],
    Guatemala: ['502', 'GT'],
    Guernsey: ['44-1481', 'GG'],
    Guinee: ['224', 'GN'],
    'Guinee-Bissau': ['245', 'GW'],
    Guyana: ['592', 'GY'],
    Haïti: ['509', 'HT'],
    'Heardeiland en McDonaldeilanden  ': ['672', 'HM'],
    Honduras: ['504', 'HN'],
    Hongarije: ['36', 'HU'],
    Hongkong: ['852', 'HK'],
    Ierland: ['353', 'IE'],
    IJsland: ['354', 'IS'],
    India: ['91', 'IN'],
    Indonesië: ['62', 'ID'],
    Irak: ['964', 'IQ'],
    Iran: ['98', 'IR'],
    Israël: ['972', 'IL'],
    Italië: ['39', 'IT'],
    Ivoorkust: ['225', 'CI'],
    Jamaica: ['1-876', 'JM'],
    Japan: ['81', 'JP'],
    Jemen: ['967', 'YE'],
    Jersey: ['44-1534', 'JE'],
    Jordanië: ['962', 'JO'],
    Kaaimaneilanden: ['1-345', 'KY'],
    Kaapverdië: ['238', 'CV'],
    Kameroen: ['237', 'CM'],
    Kazachstan: ['7', 'KZ'],
    Kenia: ['254', 'KE'],
    Kirgizië: ['996', 'KG'],
    Kiribati: ['686', 'KI'],
    Koeweit: ['965', 'KW'],
    Kroatië: ['385', 'HR'],
    Laos: ['856', 'LA'],
    Lesotho: ['266', 'LS'],
    Letland: ['371', 'LV'],
    Libanon: ['961', 'LB'],
    Liberia: ['231', 'LR'],
    Libië: ['218', 'LY'],
    Liechtenstein: ['423', 'LI'],
    Litouwen: ['370', 'LT'],
    Luxemburg: ['352', 'LU'],
    Macau: ['853', 'MO'],
    Macedonië: ['389', 'MK'],
    Madagaskar: ['261', 'MG'],
    Malawi: ['265', 'MW'],
    Maldiven: ['960', 'MV'],
    Maleisië: ['60', 'MY'],
    Mali: ['223', 'ML'],
    Malta: ['356', 'MT'],
    Man: ['44-1624', 'IM'],
    Marokko: ['212', 'MA'],
    'Marshall eilanden': ['692', 'MH'],
    Martinique: ['596', 'MQ'],
    Mauritanië: ['222', 'MR'],
    Mauritius: ['230', 'MU'],
    Mayotte: ['262', 'YT'],
    Mexico: ['52', 'MX'],
    Micronesia: ['691', 'FM'],
    Moldavië: ['373', 'MD'],
    Monaco: ['377', 'MC'],
    Mongolië: ['976', 'MN'],
    Montenegro: ['382', 'ME'],
    Montserrat: ['1-664', 'MS'],
    Mozambique: ['258', 'MZ'],
    Myanmar: ['95', 'MM'],
    Namibië: ['264', 'NA'],
    Nauru: ['674', 'NR'],
    Nederland: ['31', 'NL'],
    Nepal: ['977', 'NP'],
    Nicaragua: ['505', 'NI'],
    'Nieuw-Caledonië': ['687', 'NC'],
    'Nieuw-Zeeland': ['64', 'NZ'],
    Niger: ['227', 'NE'],
    Nigeria: ['234', 'NG'],
    Niue: ['683', 'NU'],
    'Noordelijke Marianen': ['1-670', 'MP'],
    'Noord-Korea': ['850', 'KP'],
    Noorwegen: ['47', 'NO'],
    Norfolk: ['672', 'NF'],
    Oeganda: ['256', 'UG'],
    Oekraïne: ['380', 'UA'],
    Oezbekistan: ['998', 'UZ'],
    Oman: ['968', 'OM'],
    Oostenrijk: ['43', 'AT'],
    'Oost-Timor': ['670', 'TL'],
    Pakistan: ['92', 'PK'],
    Palau: ['680', 'PW'],
    Palestina: ['970', 'PS'],
    Panama: ['507', 'PA'],
    'Papoea-Nieuw-Guinea': ['675', 'PG'],
    Paraguay: ['595', 'PY'],
    Peru: ['51', 'PE'],
    Pitcairneilanden: ['649', 'PN'],
    Polen: ['48', 'PL'],
    Portugal: ['351', 'PT'],
    'Puerto Rico (1-787)': ['1-787', 'PR'],
    'Puerto Rico (1-939)': ['1-939', 'PR'],
    Qatar: ['974', 'QA'],
    Réunion: ['262', 'RE'],
    Roemenië: ['40', 'RO'],
    Rusland: ['7', 'RU'],
    Rwanda: ['250', 'RW'],
    'Saint Kitts en Nevis': ['1-869', 'KN'],
    'Saint Lucia': ['1-758', 'LC'],
    'Saint Vincent en de Grenadines': ['1-784', 'VC'],
    'Saint-Barthélemy': ['590', 'BL'],
    'Saint-Pierre en Miquelon': ['508', 'PM'],
    Salomonseilanden: ['677', 'SB'],
    Samoa: ['685', 'WS'],
    'San Marino': ['378', 'SM'],
    'Sao Tomé en Principe': ['239', 'ST'],
    'Saoedi-Arabië': ['966', 'SA'],
    Senegal: ['221', 'SN'],
    Servië: ['381', 'RS'],
    Seychellen: ['248', 'SC'],
    'Sierra Leone': ['232', 'SL'],
    Singapore: ['65', 'SG'],
    'Sint Maarten': ['1-721', 'SX'],
    'Sint-Helena': ['290', 'SH'],
    'Sint-Lucia': ['758', 'LC'],
    'Sint Maarten Eiland': ['590', 'MF'],
    Slovakije: ['421', 'SK'],
    Slovenië: ['386', 'SI'],
    Soedan: ['249', 'SD'],
    Somalië: ['252', 'SO'],
    Spanje: ['34', 'ES'],
    'Spitsbergen en Jan Mayen': ['47', 'SJ'],
    'Sri Lanka': ['94', 'LK'],
    Suriname: ['597', 'SR'],
    Swaziland: ['268', 'SZ'],
    Syrië: ['963', 'SY'],
    Tadzjikistan: ['992', 'TJ'],
    Taiwan: ['886', 'TW'],
    Tanzania: ['255', 'TZ'],
    Thailand: ['66', 'TH'],
    Togo: ['228', 'TG'],
    Tokelau: ['690', 'TK'],
    Tonga: ['676', 'TO'],
    'Trinidad en Tobago': ['1-868', 'TT'],
    Tsjaad: ['235', 'TD'],
    Tsjechië: ['420', 'CZ'],
    Tunesië: ['216', 'TN'],
    Turkije: ['90', 'TR'],
    Turkmenistan: ['993', 'TM'],
    'Turks- en Caicoseilanden': ['1-649', 'TC'],
    Tuvalu: ['688', 'TV'],
    Uruguay: ['598', 'UY'],
    Vanuatu: ['678', 'VU'],
    Vaticaanstad: ['379', 'VA'],
    Venezuela: ['58', 'VE'],
    'Verenigd Koninkrijk': ['44', 'GB'],
    'Verenigde Arabische Emiraten': ['971', 'AE'],
    'Verenigde Staten van Amerika': ['1', 'US'],
    Vietnam: ['84', 'VN'],
    'Wallis en Futuna': ['681', 'WF'],
    'Westelijke Sahara': ['212', 'EH'],
    'Wit-Rusland of Belarus': ['375', 'BY'],
    Zambia: ['260', 'ZM'],
    Zimbabwe: ['263', 'ZW'],
    'Zuid-Afrika': ['27', 'ZA'],
    'Zuid-Georgia en de Zuidelijke Sandwicheilanden': ['500', 'GS'],
    'Zuid-Korea': ['82', 'KR'],
    'Zuid-Soedan': ['211', 'SS'],
    Zweden: ['46', 'SE'],
    Zwitserland: ['41', 'CH'],
    Afghanistan: ['93', 'AF'],
    Albanië: ['355', 'AL'],
};
