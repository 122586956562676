import { CPDate, formatCpDate } from '@cp-shared-7/common-utilities';
import moment from 'moment-timezone';
import 'moment/locale/nl';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';

// Check out https://momentjs.com/timezone/
export const NL_TIMEZONE = 'Europe/Amsterdam';

moment.tz.setDefault(NL_TIMEZONE);

// TODO Check the format definitions
const locale = 'nl';
export const numberFormat = '0,0';
export const numberFormatRoundedOne = '0,0.0';
export const numberFormatRoundedTwo = '0,0.00';
const numeralFormat = '0o';
const currencyFormat = '0,0.00';
const longDateFormat = 'DD-MM-YYYY';

export function changeLocale(locale: 'en' | 'nl'): void {
    moment.locale(locale);
    numeral.locale(locale === 'nl' ? 'nl-nl' : locale);
}

changeLocale(locale);

export function formatAsCurrency(value?: number): string {
    return typeof value === 'number' ? `€ ${numeral(value).format(currencyFormat)}` : '';
}

export function formatAsDate(date?: CPDate): string {
    if (!date) return '';
    return formatCpDate(date).format(longDateFormat);
}

export function formatAsNumber(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? numeral(value).format(format) : '';
}

export function formatAsNumeral(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numeralFormat) : '';
}

export function formatAsDistance(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numberFormat) : '';
}

export function formatAsEmission(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g/km` : '';
}

export function formatAsPercentage(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormatRoundedTwo)} %` : '';
}

export function formatAsIban(iban?: string): string {
    return (
        iban
            ?.replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim() ?? ''
    );
}
