import { AnalyticsProvider, MasterPage as MasterPageShared, useAuthentication } from '@cp-shared-7/frontend-ui';
import { IconFooter as IconFooterUi } from 'components/icon-footer';
import { identificationPagePath, loginInProgressPagePath } from 'components/navigation/paths';
import { currentBrand } from 'config/config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LegalFooter } from '../components/legal-footer';
import { NavigationBar } from '../components/navigation/NavigationBar';

export type MasterPageProps = {};

export const MasterPage: React.FC<MasterPageProps> = ({ children }) => {
    const { t } = useTranslation();
    const { isAuthenticated } = useAuthentication();

    return (
        <AnalyticsProvider
            market={'nl'}
            isAuthenticated={isAuthenticated}
            version={'1'}
            releaseDate={'2021-01-25'}
            language={'nl'}
            registrationPath={identificationPagePath()}
            loginPath={loginInProgressPagePath()}
            userGroup={'private'}
            brand={currentBrand}
        >
            <MasterPageShared
                navigationBar={<NavigationBar />}
                iconFooter={<IconFooterUi />}
                legalFooter={<LegalFooter />}
                label={t('backToTop')}
            >
                {children}
            </MasterPageShared>
        </AnalyticsProvider>
    );
};
