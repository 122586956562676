import { partition } from 'lodash';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-7/frontend-integration';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-7/common-utilities';
import { ContractCount } from '@cp-nl/common';
import { fetchContractsCount } from './ContractsCountSlice';
import { ContractsCountDataSelector } from './ContractsCountDataSelector';
import { currentBrand } from '../../../config';

/**
 * Sorts the current brand in front of all other brands
 * @param contractsCount the unsorted elements
 */
function sorter(contractsCount?: ContractCount[]): ContractCount[] {
    if (!contractsCount) {
        return [];
    }

    const [brandCount, other] = partition(contractsCount, ({ brand }) => brand === currentBrand);
    if (other.length === 0) {
        return [];
    }

    return [...brandCount, ...other];
}

export function useSortedContractsCount(): AbstractDataState<ContractCount[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiDataWithTransformer(fetchContractsCount, ContractsCountDataSelector, sorter);
}
