import React from 'react';
import {
    HeroImage as LandingPageTeaserUi,
    LandingPageMarketingCardLoadingPlaceholder,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    useAuthentication,
} from '@cp-shared-7/frontend-ui';
import { useLandingPageTeaser } from './useLandingPageTeaser';
import { dashboardPagePath } from '../navigation/paths';
import { useTranslation } from 'react-i18next';
import { idpHint } from '../../config';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

const LandingPageTeaserWithHandlers = withLoadingAndNoConnectionHandler(LandingPageTeaserUi);

export const LandingPageTeaser: React.FC = () => {
    const { cmsContent: landingPageTeaser, isLoading, loadingError } = useLandingPageTeaser();
    const { i18n } = useTranslation();
    const { login } = useAuthentication();

    const language = i18n.languages[0];

    const { onAction } = useAnalyticsActionTracker('login');
    useAnalyticsPageViewTracker('landingPage', true);

    const onClick = (): void => {
        onAction();
        login({
            redirectUri: window.location.origin + dashboardPagePath(),
            locale: language,
            idpHint,
            prompt: 'login',
        });
    };

    return (
        <LandingPageTeaserWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={landingPageTeaser?.title || ''}
            subTitle={landingPageTeaser?.subTitle}
            text={landingPageTeaser?.text}
            imageUrl={landingPageTeaser?.imageUrl || ''}
            buttonText={landingPageTeaser?.buttonText}
            clickHandler={onClick}
            inverted={!!landingPageTeaser?.isInverted}
            loadingPlaceholder={<LandingPageMarketingCardLoadingPlaceholder />}
        />
    );
};
