import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactDetails } from '@cp-nl/common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-7/frontend-ui';

export const ContactList: React.FC<{ contactDetails: ContactDetails }> = ({ contactDetails }) => {
    const { t } = useTranslation('my-profile');

    const { mobilePhone, mainPhone, email } = contactDetails;

    const contactList: DefinitionListItem[] = [
        {
            label: t('contact-details.email'),
            value: email || '-',
            testId: 'contact-details-email',
        },
        {
            label: t('contact-details.mobile-phone'),
            value: mobilePhone || '-',
            testId: 'contact-details-mobile-phone',
        },
        {
            label: t('contact-details.main-phone'),
            value: mainPhone,
            testId: 'contact-details-main-phone',
        },
    ].filter((item) => item.value);

    return <DefinitionList split={true} list={contactList} />;
};
