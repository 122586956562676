import { ContactDetailsCheckContent, OpenVerificationInfo } from '@cp-nl/common';
import { NotificationStatus, preventSubmit, ValidatedInput, ValidatedSelectItem } from '@cp-shared-7/frontend-ui';
import { Button, Form, Heading, Layout, FormField, Select, Notification } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import { countryCodeMapping } from '../../../my-profile/contact-section/edit-view/country-code';
import { ContactDetailsCheckAction } from '../types';

export const optionsFromCountryMapping = (): ValidatedSelectItem[] => {
    return Object.entries(countryCodeMapping).map((value: [string, string[]]) => ({
        label: value[0],
        value: value[1][0],
    }));
};

type ContactDetailsCheckFormProps = {
    formValues: OpenVerificationInfo;
    content: ContactDetailsCheckContent;
    onSubmit: (values: OpenVerificationInfo, action: ContactDetailsCheckAction) => void;
    submitError?: string;
};

export const ContactDetailsCheckForm: React.FC<ContactDetailsCheckFormProps> = ({
    formValues,
    content,
    onSubmit,
    submitError,
}) => {
    const [prefixMobilePhone, setPrefixMobilePhone] = useState(formValues.mobileNumberCountryCode);
    let action: ContactDetailsCheckAction;

    const handlerChangeAreaCodeMobilePhone = (
        countryCode: string,
        setFieldValue: (name: string, value: string) => void,
    ): void => {
        setPrefixMobilePhone(countryCode);
        setFieldValue('mobileNumberCountryCode', countryCode);
    };

    const formikSubmit = (values: OpenVerificationInfo) => {
        onSubmit({ ...values, mobileNumberCountryCode: prefixMobilePhone }, action);
    };

    return (
        <>
            {formValues.inviteEmail && (
                <Notification
                    testId="ExistingNotification"
                    status={NotificationStatus.warning}
                    visible
                    showCloseButton={false}
                >
                    {content.existingNotification.replaceAll('<InviteEmail>', formValues.inviteEmail)}
                </Notification>
            )}
            {!formValues.inviteEmail &&
                formValues.SFmail &&
                formValues.fsIdEmail &&
                formValues.SFmail !== formValues.fsIdEmail && (
                    <Notification
                        testId="MismatchNotification"
                        status={NotificationStatus.warning}
                        visible
                        showCloseButton={false}
                    >
                        {content.mismatchNotification
                            .replaceAll('<SF-email>', formValues.SFmail)
                            .replaceAll('<FSID-emailadres>', formValues.fsIdEmail)}
                    </Notification>
                )}
            {submitError && (
                <Notification
                    testId="ErrorNotification"
                    status={NotificationStatus.error}
                    visible
                    title={content.errorNotificationTitle}
                    showCloseButton={false}
                >
                    {<div dangerouslySetInnerHTML={{ __html: content.errorNotification }} />}
                </Notification>
            )}
            <Formik initialValues={formValues} onSubmit={formikSubmit}>
                {(formik) => (
                    <Form onSubmit={preventSubmit()}>
                        <Layout>
                            <Layout.Item>
                                <Heading level={1}>{content?.headline}</Heading>
                            </Layout.Item>
                            {formValues.SFmail === formValues.fsIdEmail && (
                                <Layout.Item>{content?.description}</Layout.Item>
                            )}
                            <Layout.Item default="1/2">
                                <FormField type="select" labelText={content?.land}>
                                    <Select
                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                            handlerChangeAreaCodeMobilePhone(e.target.value, formik.setFieldValue)
                                        }
                                        defaultValue={prefixMobilePhone}
                                    >
                                        {<Select.Item hidden />}
                                        {optionsFromCountryMapping().map((item, index) => {
                                            return (
                                                <Select.Item key={index} value={item.value}>
                                                    {item.label}
                                                </Select.Item>
                                            );
                                        })}
                                    </Select>
                                </FormField>
                            </Layout.Item>
                            <Layout.Item default="1/2">
                                <ValidatedInput
                                    label={content?.mobilePhone || ''}
                                    tooltip={content?.mobilePhoneTooltip}
                                    name="mobileNumber"
                                    testId="mobileNumber"
                                    addonText={`+${prefixMobilePhone}`}
                                    reversed
                                    type="tel"
                                    isMandatory={true}
                                />
                            </Layout.Item>
                            {formValues.SFmail === formValues.fsIdEmail && (
                                <Layout.Item>
                                    <Button
                                        onClick={() => {
                                            action = 'CONTINUE';
                                            formik.handleSubmit();
                                        }}
                                        testId="submit-button"
                                        type="button"
                                    >
                                        {content?.continue}
                                    </Button>
                                </Layout.Item>
                            )}
                            {!formValues.inviteEmail && formValues.SFmail !== formValues.fsIdEmail && (
                                <Layout.Item>
                                    <Button
                                        onClick={() => {
                                            action = 'CONTINUE';
                                            formik.handleSubmit();
                                        }}
                                        testId="continue-button"
                                        type="button"
                                    >
                                        {content?.continue}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            action = 'LOGIN';
                                            formik.handleSubmit();
                                        }}
                                        testId="submit-button"
                                        type="button"
                                        className={'u-ml-small'}
                                    >
                                        {content?.cancel}
                                    </Button>
                                </Layout.Item>
                            )}
                            {formValues.inviteEmail && (
                                <Layout.Item>
                                    <Button
                                        onClick={() => {
                                            action = 'LOGIN';
                                            formik.handleSubmit();
                                        }}
                                        testId="submit-button"
                                        type="button"
                                    >
                                        {content?.login}
                                    </Button>
                                </Layout.Item>
                            )}
                        </Layout>
                    </Form>
                )}
            </Formik>
        </>
    );
};
