import {
    Address,
    ChangeAddress,
    ChangeAddressQuery,
    getAddressChangeEndpoint,
    getAddressDataEndpoint,
} from '@cp-nl/common';
import {
    CleaveInput,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    ValidatedInput,
} from '@cp-shared-7/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout, Notification } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditStatus, NotificationForEditStatus } from '../../../address-view/NotificationForEditStatus';
import { AddressFormNotNL } from '../address-form-not-nl/AddressFormNotNL';
import { validationSchemaNL } from '../validationSchema';

type ChangeAddressProps = {
    formValues: ChangeAddress;
    onCancel: (values?: ChangeAddress) => void;
    finishEditing: (newEditStatus: EditStatus, updatedAddress?: Address) => void;
    onEdit: (addressType: string) => void;
    addressType: string;
    address?: Address;
};

export const AddressFormNL: React.FC<ChangeAddressProps> = ({
    formValues,
    onCancel,
    onEdit,
    finishEditing,
    addressType,
    address,
}) => {
    const { t } = useTranslation('change-address');
    const [isGettingAddress, setIsGettingAddress] = useState(false);
    const [editAfterSearch, setEditAfterSearch] = useState(false);
    const [showFullAddress, setShowFullAddress] = useState(false);
    const [showAddressNotFound, setShowAddressNotFound] = useState(false);
    const [fullAddress, setFullAddress] = useState({
        firstLine: '',
        secondLine: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingPossible, setSubmittingPossible] = useState(false);

    const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileAddressValidationError');
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, 'Address');
    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onEditProfileAddressTypedIn',
    });
    const getInitialErrors = (values: { [k: string]: string }) =>
        ['postCode', 'houseNumber'].filter((element) => values[element] === '').join(', ');
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);

    const [formValuesState, setFormValuesState] = useState<ChangeAddress>({
        country: formValues?.country || '',
        postCode: formValues?.postCode || '',
        houseNumber: formValues?.houseNumber || '',
        door: formValues?.door || '',
        street: formValues?.street || '',
        locality: formValues?.locality || '',
        extraInfo: formValues?.extraInfo || '',
    });

    const handleAddressSearch = () => {
        setIsGettingAddress(true);
        const query: ChangeAddressQuery = {
            zipCode: formValuesState.postCode,
            houseNumber: formValuesState.houseNumber,
            houseNumberAddition: formValuesState.door,
        };
        CpDataApi.get(getAddressDataEndpoint(), { params: query })
            .then((response) => {
                setFullAddress({
                    firstLine: `${response.data.StreetName} ${formValuesState.houseNumber} ${formValuesState.door}`,
                    secondLine: `${formValuesState.postCode} ${response.data.City}`,
                });
                setFormValuesState({
                    ...formValuesState,
                    street: response.data.StreetName,
                    locality: response.data.City,
                });
                setShowAddressNotFound(false);
                setShowFullAddress(true);
                setSubmittingPossible(true);
            })
            .catch(() => {
                setShowFullAddress(false);
                setShowAddressNotFound(true);
                setSubmittingPossible(false);
            })
            .finally(() => {
                setIsGettingAddress(false);
            });
    };

    const handlerChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
        setFormValuesState({
            ...formValuesState,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = () => {
        const preparedValues: ChangeAddress = {
            country: formValuesState.country,
            postCode: formValuesState.postCode,
            houseNumber: formValuesState.houseNumber,
            door: formValuesState.door,
            street: formValuesState.street,
            locality: formValuesState.locality,
            extraInfo: formValuesState.extraInfo,
            addressType: addressType,
            noAddressCheck: false,
        };

        setIsSubmitting(true);

        CpDataApi.put(getAddressChangeEndpoint(), preparedValues)
            .then((response) => {
                const preparedChanges: Address =
                    addressType === 'Visit'
                        ? {
                              visitAddressCountry: preparedValues.country,
                              visitAddressLine1: `${preparedValues.street} ${preparedValues.houseNumber} ${preparedValues.door}`,
                              visitAddressLine2: `${preparedValues.postCode} ${preparedValues.locality}`,
                              postalAddressCountry: address?.postalAddressCountry,
                              postalAddressLine1: address?.postalAddressLine1,
                              postalAddressLine2: address?.postalAddressLine2,
                          }
                        : {
                              postalAddressCountry: preparedValues.country,
                              postalAddressLine1: `${preparedValues.street} ${preparedValues.houseNumber} ${preparedValues.door}`,
                              postalAddressLine2: `${preparedValues.postCode} ${preparedValues.locality}`,
                              visitAddressCountry: address?.visitAddressCountry,
                              visitAddressLine1: address?.visitAddressLine1,
                              visitAddressLine2: address?.visitAddressLine2,
                          };

                const updatedAddress = preparedChanges;

                const result = response.data[0].ElementName;
                if (result === 'addressChangeSuccessful') {
                    finishEditing(EditStatus.SUCCESS, updatedAddress);
                } else if (result === 'ForeignChange') {
                    finishEditing(EditStatus.FOREIGN_CHANGE);
                } else if (result === 'ManualChange') {
                    finishEditing(EditStatus.MANUAL_CHANGE);
                }
            })
            .catch(() => {
                finishEditing(EditStatus.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <>
            {(isSubmitting || isGettingAddress) && <Spinner fullPage={true} />}
            {!editAfterSearch && (
                <Formik initialValues={formValues} onSubmit={handleSubmit} validationSchema={validationSchemaNL(t)}>
                    {(formik) => (
                        <Form
                            onSubmit={preventSubmit()}
                            onChange={() => onTyping(formik.errors, formik.touched)}
                            data-testid="addressChangeFormNL"
                        >
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="1/4">
                                            <CleaveInput
                                                handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    handlerChangeInput(e)
                                                }
                                                name="postCode"
                                                label={t('form.post-code')}
                                                cleaveOptions={{
                                                    blocks: [4, 2],
                                                    delimiter: ' ',
                                                }}
                                                testId="postCode"
                                                autocomplete="postal-code"
                                            />
                                        </Layout.Item>
                                        <Layout.Item default="1/4">
                                            <ValidatedInput
                                                label={t('form.house-number')}
                                                name="houseNumber"
                                                testId="houseNumber"
                                                type="text"
                                                handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    handlerChangeInput(e)
                                                }
                                                autocomplete="address-line2"
                                            />
                                        </Layout.Item>
                                        <Layout.Item default="1/4">
                                            <ValidatedInput
                                                label={t('form.door')}
                                                name="door"
                                                testId="door"
                                                type="text"
                                                handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    handlerChangeInput(e)
                                                }
                                            />
                                        </Layout.Item>
                                        <Layout.Item default="1/4">
                                            <Button
                                                className="u-mt c-form-field"
                                                testId="searchAddressButton"
                                                onClick={() => {
                                                    formik.setTouched({ postCode: true, houseNumber: true }, true);
                                                    if (formik.isValid) {
                                                        handleAddressSearch();
                                                    }
                                                }}
                                            >
                                                {t('form.find-address-button')}
                                            </Button>
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                {showFullAddress && (
                                    <>
                                        <h4>{t('address-search-result')}</h4>
                                        <Notification showCloseButton={false} visible testId="addressFoundNotification">
                                            <>
                                                {fullAddress.firstLine} <br /> {fullAddress.secondLine}
                                            </>
                                        </Notification>
                                    </>
                                )}
                                {showAddressNotFound && (
                                    <>
                                        <NotificationForEditStatus editStatus={EditStatus.ADDRESS_NOT_FOUND} />
                                        <Button
                                            link
                                            small
                                            className="u-pb-small u-mt"
                                            icon="semantic-forward"
                                            iconReversed
                                            onClick={() => setEditAfterSearch(true)}
                                        >
                                            {t('address-manual-input-link')}
                                        </Button>
                                    </>
                                )}
                                <Fieldset.Row>
                                    <ButtonContainer center className="u-mt">
                                        <Button onClick={onCancel} secondary testId="cancelButton">
                                            {t('form.cancel-button')}
                                        </Button>
                                        <Button
                                            disabled={!isSubmittingPossible}
                                            onClick={() => {
                                                formik.handleSubmit();
                                                const initialErrors = getInitialErrors({
                                                    postCode: formik.values.postCode,
                                                    houseNumber: formik.values.houseNumber,
                                                });
                                                if (!isEmpty(formik.errors)) {
                                                    const errorsList = getErrors(formik.errors);
                                                    onValidationError(errorsList);
                                                } else if (!!initialErrors) {
                                                    onValidationError(initialErrors);
                                                }
                                            }}
                                            type="submit"
                                            testId="submitButton"
                                        >
                                            {t('form.submit-button')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            )}
            {editAfterSearch && <AddressFormNotNL {...{ formValues, onCancel, finishEditing, onEdit, addressType }} />}
        </>
    );
};
