import React from 'react';
import { useDispatch } from 'react-redux';
import { ContextNotification, Notification } from '@vwfs-bronson/bronson-react';
import { useOtherBrandsAvailableModal } from './useOtherBrandsAvailableModal';
import { useSortedContractsCount } from '../navigation/contracts-count/useContractsCount';
import { useOtherBrandsAvailableModalClosed } from './useOtherBrandsAvailableModalClosed';
import { setClosed } from './otherBrandsAvailableModalClosedSlice';

export const OtherBrandsAvailableModal: React.FC = () => {
    const dispatch = useDispatch();
    const { cmsContent: otherBrandsAvailableModal } = useOtherBrandsAvailableModal();
    const { data, isLoading, loadingError } = useSortedContractsCount();
    const { closed } = useOtherBrandsAvailableModalClosed();

    if (closed || !data?.length || isLoading || loadingError || !otherBrandsAvailableModal) {
        return null;
    }

    return (
        <ContextNotification>
            <Notification
                title={otherBrandsAvailableModal.header}
                onCloseClick={() => dispatch(setClosed(true))}
                showCloseButton={true}
                status="info"
                visible
            >
                {otherBrandsAvailableModal.body}
            </Notification>
        </ContextNotification>
    );
};
