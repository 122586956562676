import * as Yup from 'yup';
import { TFunction } from 'i18next';

const removeSpacesForMaxLength = (phone?: string | null): boolean => {
    return !(phone && phone?.replace(/\s/g, '').length > 15);
};

const isCountryCodeNL = (phone?: string | null, prefixForPhone?: string): boolean => {
    return !(phone && prefixForPhone === '31' && phone?.replace(/\s/g, '').length !== 10);
};

export const validationSchema = (t: TFunction, prefixMobilePhone: string, productTypeName: string) => {
    const requiredEmailError = 'validation-error.email.required';
    const invalidEmailError = 'validation-error.email.invalid';
    const mobileNumberRequiredError = 'validation-error.mobile-number.required';
    const mobileNumberInvalidError = 'validation-error.mobile-number.invalid';
    const consentFlagError = 'validation-error.consent-flag';
    const companyNameError = 'validation-error.company-name';
    const companyIDError = 'validation-error.companyID';
    const firstNameError = 'validation-error.first-name';
    const lastNameError = 'validation-error.last-name';
    const requiredInitialsError = 'validation-error.initials';

    return Yup.object().shape({
        email: Yup.string().trim().required(t(requiredEmailError)).email(t(invalidEmailError)),
        mobileNumber: Yup.string()
            .trim()
            .required(t(mobileNumberRequiredError))
            .matches(/^[\d ()]*$/, t(mobileNumberInvalidError))
            .test('country code NL is choosen', t(mobileNumberInvalidError), (mobilePhone) => {
                return isCountryCodeNL(mobilePhone, prefixMobilePhone);
            })
            .test('not count spaces for max lenght', t(mobileNumberInvalidError), (mobileNumber) => {
                return removeSpacesForMaxLength(mobileNumber);
            }),
        mobileNumberCountryCode: Yup.string().required(),
        consentFlag: Yup.bool().required().oneOf([true], t(consentFlagError)),
        companyName: productTypeName?.includes('Financial Lease')
            ? Yup.string().required(t(companyNameError))
            : Yup.string(),
        companyID: productTypeName?.includes('Financial Lease')
            ? Yup.string().required(t(companyIDError))
            : Yup.string(),
        firstName: Yup.string().required(t(firstNameError)),
        lastName: Yup.string().required(t(lastNameError)),
        initials: Yup.string()
            .required(t(requiredInitialsError))
            .matches(/^([A-Z]\.)+$/, ' '),
    });
};
