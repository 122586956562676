import React from 'react';
import { NotificationStatus } from '@cp-shared-7/frontend-ui';
import { Notification } from '@vwfs-bronson/bronson-react';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { useCmsContent } from '../../../../utils/useCmsContent';
import { ErrorsContent } from '@cp-nl/common/build/apis/cms/types/open-verification-content';

type AlertProps = {
    errorCode: string;
};

export const OpenVerificationAlert: React.FC<AlertProps> = ({ errorCode }) => {
    const [cmsContent] = useCmsContent<ErrorsContent>('open-verification-content');

    if (!cmsContent) {
        return null;
    }

    const getNotificationProps = (): { text: JSX.Element | string; headline: string | undefined } | undefined => {
        switch (errorCode) {
            case 'CUSTOMER_NOT_KNOWN':
                return {
                    headline: cmsContent?.errors?.customerNotKnown.header,
                    text: (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: cmsContent?.errors?.customerNotKnown.content,
                            }}
                        />
                    ),
                };
            case 'IDENTITY_NOT_FOUND':
                return {
                    headline: cmsContent?.errors.customerIdentityNotFound.header,
                    text: (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: cmsContent?.errors.customerIdentityNotFound.content,
                            }}
                        />
                    ),
                };
            case 'MAX_ATTEMPTS_REACHED':
                return {
                    headline: '',
                    text: <span dangerouslySetInnerHTML={{ __html: cmsContent?.errors?.maxAttemptsReach.content }} />,
                };
        }
    };

    const notificationProps = getNotificationProps();

    return notificationProps ? (
        <Notification
            title={notificationProps.headline}
            status={NotificationStatus.error}
            testId="open-verification-error"
            visible
            showCloseButton={false}
        >
            {notificationProps.text}
        </Notification>
    ) : (
        <NoConnectionNotification testId="no-connection-notification" />
    );
};
