import { CPDate, DefaultBusinessMarketApiErrorCode } from '@cp-shared-7/common-utilities';
import { MarketApiErrorCode } from './errorCodes';
import { Brand } from './brands';

export interface ContractsData {
    /**
     * Valid contracts with all mandatory fields present
     */
    contracts?: Contract[];
    /**
     * Flag to identify if there is contract with missing mandatory field to show the corresponding error message
     */
    isContractWithoutMandatoryFieldPresent: boolean;
}

export interface Contract {
    // contractId (technical) which should be used if contractId needs to be send to market API
    encryptedContractVersionId: string;
    carBrand?: string;
    carModel?: string;
    productType?: string;
    // contractId which should be shown to the user
    contractNumber: string;
    licensePlate?: string;
    vinCode?: string;
    encryptedVinCode?: string;
    nextDueDate?: CPDate;
    nextDueAmount?: number;
    outstandingBalance?: number;
    contractStatus: ContractStatus;
    unpaidBalance?: UnpaidBalance;
    contractStartDate?: CPDate;
    contractEndDate?: CPDate;
    brokerBusinessPartnerId?: string;
    _links?: ContractLinks;
}

export enum ContractStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
}

export type UnpaidBalance = {
    unpaidPresent?: boolean;
    nominalAmount?: number;
    arrearsAmount?: number;
    totalUnpaidAmount?: number;
    pendingInstallments?: number;
    interestOnArrears?: number;
    nextDueDate?: CPDate;
    paymentDetailsEntity?: string;
    paymentDetailsReference?: string;
};

export type ContractLinks = {
    financialDetails?: string;
    interveningParties?: string;
};

export type NoContractFoundError = 'NO_CONTRACT_FOUND_FOR_GIVEN_BRAND';

export type ContractErrorCode = DefaultBusinessMarketApiErrorCode | MarketApiErrorCode | NoContractFoundError;

export type ContractHandover = {
    companyName?: string;
    companyID?: string;
    gender: string;
    initials: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    email: string;
    mobileNumberCountryCode: string;
    mobileNumber: string;
};

export type ContractHandoverApiError = 'HANDOVER_API_ERROR';

export interface ContractCount {
    brand: Brand;
    numberOfContracts: number;
}
