import { useContract } from 'components/contracts/useContract';
import React from 'react';
import { UnpaidBalanceDetailsUi } from './ui/UnpaidBalanceDetailsUi';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

const UnpaidBalanceWithHandlers = withLoadingAndNoConnectionHandler(UnpaidBalanceDetailsUi);
type UnpaidBalanceProps = {
    encryptedContractNumber: string;
};

export const UnpaidBalanceDetails: React.FC<UnpaidBalanceProps> = ({ encryptedContractNumber }) => {
    const { data: contract, isLoading, loadingError } = useContract(encryptedContractNumber, {
        encryptedContractNumber: true,
    });

    return <UnpaidBalanceWithHandlers contract={contract} isLoading={isLoading} hasError={!!loadingError} />;
};
