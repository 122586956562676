import { FinancialDetails } from '@cp-nl/common';
import { useAnalyticsEnterViewportTracker } from '@cp-shared-7/frontend-ui';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React from 'react';

import { BaseSection } from '../base-section/BaseSection';

export const FinancialDetailsUi: React.FC<{ financialDetails?: FinancialDetails }> = ({ financialDetails }) => {
    const { ref } = useAnalyticsEnterViewportTracker('onOpenContractAccordion', 'Financial Details');

    if (!financialDetails) {
        return <NoConnectionNotification />;
    }

    return (
        <div ref={ref}>
            <BaseSection financialDetails={financialDetails} />
        </div>
    );
};
