import { ContactDetails, ContactDetailsChange, getChangeContactDetailsEndpoint } from '@cp-nl/common';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    ValidatedInput,
    ValidatedSelectItem,
} from '@cp-shared-7/frontend-ui';
import {
    Button,
    ButtonContainer,
    DataOverview,
    Fieldset,
    Form,
    Layout,
    FormField,
    Select,
} from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from '../../../../cp-xhr';
import { countryCodeMapping } from './country-code';
import { getAreaCode, removeAreaCodeFromPhoneNumber } from './helper';
import { validationSchema } from './validationSchema';
import { isEmpty } from 'lodash';
import { ContactDetailsChangeForm } from '../types';

export enum EditStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    NOT_PERFORMED = 'NOT_PERFORMED',
}

export type EditViewProps = {
    contactDetails?: ContactDetails;
    formValues?: ContactDetailsChangeForm;
    cancelEditing: (formValue?: ContactDetailsChangeForm) => void;
    finishEditing: (newEditStatus: EditStatus, updatedContactDetails?: ContactDetails) => void;
};

export const optionsFromCountryMapping = (): ValidatedSelectItem[] => {
    return Object.entries(countryCodeMapping).map((value: [string, string[]]) => ({
        label: value[0],
        value: value[1][0],
    }));
};

export const EditView: React.FC<EditViewProps> = ({ contactDetails, formValues, cancelEditing, finishEditing }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [prefixMobilePhone, setPrefixMobilePhone] = useState(
        formValues?.mobilePhoneCountryCode || getAreaCode(contactDetails?.mobilePhone),
    );
    const [prefixMainPhone, setPrefixMainPhone] = useState(
        formValues?.mainPhoneCountryCode || getAreaCode(contactDetails?.mainPhone),
    );
    const [formValuesState, setFormValuesState] = useState<ContactDetailsChangeForm>({
        emailAddress: formValues?.emailAddress || '',
        mobilePhoneCountryCode: formValues?.mobilePhoneCountryCode || '',
        mobilePhone: formValues?.mobilePhone || '',
        mainPhoneCountryCode: formValues?.mainPhoneCountryCode || '',
        mainPhone: formValues?.mainPhone || '',
    });

    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, 'Contact');
    const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileContactValidationError');
    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onEditProfileContactTypedIn',
    });

    const getInitialErrors = (values: { [k: string]: string | undefined }) =>
        ['emailAddress', 'mobilePhoneCountryCode', 'mobilePhone', 'mainPhoneCountryCode']
            .filter((element) => !values[element] || values[element] === '')
            .join(', ');
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);

    const { t } = useTranslation('contact-details-edit');
    const { email, mobilePhone, mainPhone } = contactDetails || {
        email: '',
        mobilePhone: '',
        mainPhone: '',
    };

    const initialValues: ContactDetailsChangeForm = {
        emailAddress: formValuesState.emailAddress || email,
        mobilePhoneCountryCode: formValuesState.mobilePhoneCountryCode || getAreaCode(mobilePhone),
        mobilePhone: formValuesState.mobilePhone || removeAreaCodeFromPhoneNumber(mobilePhone),
        mainPhoneCountryCode: formValuesState.mainPhoneCountryCode || getAreaCode(mainPhone),
        mainPhone: formValuesState.mainPhone || removeAreaCodeFromPhoneNumber(mainPhone),
        oldEmailAddress: '',
    };

    const handlerChangeAreaCodeMobilePhone = (
        event: ChangeEvent<HTMLInputElement>,
        setFieldValue: (name: string, value: string) => void,
    ): void => {
        setFormValuesState({
            ...formValuesState,
            mobilePhoneCountryCode: event.target.value,
        });
        setFieldValue('mobilePhoneCountryCode', event.target.value);
        setPrefixMobilePhone(event.target.value);
    };

    const handlerChangeAreaCodeMainPhone = (
        event: ChangeEvent<HTMLInputElement>,
        setFieldValue: (name: string, value: string) => void,
    ): void => {
        setFormValuesState({
            ...formValuesState,
            mainPhoneCountryCode: event.target.value,
        });
        setFieldValue('mainPhoneCountryCode', event.target.value);
        setPrefixMainPhone(event.target.value);
    };

    const handlerChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
        setFormValuesState({
            ...formValuesState,
            [e.target.name]: e.target.value,
        });
    };

    const handlerChangeEmail = (e: string): void => {
        setFormValuesState({
            ...formValuesState,
            emailAddress: e,
        });
    };

    const onSubmit = (changeValues: ContactDetailsChangeForm): void => {
        const preparedChangeValues: ContactDetailsChange = {
            emailAddress: changeValues.emailAddress,
            mobilePhoneCountryCode: `+${prefixMobilePhone}`,
            mobilePhone: changeValues.mobilePhone.replace(/\s/g, ''),
            mainPhoneCountryCode: changeValues.mainPhoneCountryCode ? `+${prefixMainPhone}` : '',
            mainPhone: changeValues.mainPhone?.replace(/\s/g, ''),
            oldEmailAddress: changeValues.emailAddress !== email ? email : '',
        };

        setIsSubmitting(true);

        CpDataApi.post(getChangeContactDetailsEndpoint(), preparedChangeValues)
            .then(() => {
                const preparedChanges: ContactDetails = {
                    email: changeValues.emailAddress,
                    mobilePhone: `+${prefixMobilePhone} ${changeValues.mobilePhone.replace(/\s/g, '')}`,
                    mainPhone: changeValues.mainPhone
                        ? `+${prefixMainPhone} ${changeValues.mainPhone?.replace(/\s/g, '')}`
                        : '',
                };

                const updatedContact = { ...contactDetails, ...preparedChanges };
                setIsSubmitting(false);
                finishEditing(EditStatus.SUCCESS, updatedContact);
            })
            .catch(() => {
                setIsSubmitting(false);
                finishEditing(EditStatus.ERROR);
            });
    };

    return (
        <DataOverview title={t('contact-details.headline')}>
            <>
                <Notification status={NotificationStatus.warning} className={'u-mb'}>
                    {t('contact-details.info')}
                </Notification>
                <UiBlockingSpinner isBlocking={isSubmitting}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema(t, prefixMobilePhone, prefixMainPhone)}
                        onSubmit={onSubmit}
                    >
                        {(formik) => (
                            <Form
                                onSubmit={preventSubmit()}
                                onChange={() => onTyping(formik.errors, formik.touched)}
                                data-testid="edit-form"
                            >
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item>
                                                <ValidatedInput
                                                    handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                        handlerChangeEmail(e.target.value)
                                                    }
                                                    label={t('email')}
                                                    name="emailAddress"
                                                    testId="email-address"
                                                    type="email"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/2" s="1/1">
                                                <Layout.Item default="1/1" className={'u-pl-none'}>
                                                    <h5>{t('mobile-phone')}</h5>
                                                </Layout.Item>
                                                <Layout.Item default="1/2" s="1/1" className={'u-pl-none'}>
                                                    <FormField type="select" labelText={t('mobile-phone-country-code')}>
                                                        <Select
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                handlerChangeAreaCodeMobilePhone(
                                                                    e,
                                                                    formik.setFieldValue,
                                                                )
                                                            }
                                                            defaultValue={prefixMobilePhone}
                                                            id="prefixMobilePhone"
                                                            testId="prefixMobilePhone"
                                                        >
                                                            {optionsFromCountryMapping().map((item, index) => {
                                                                return (
                                                                    <Select.Item key={index} value={item.value}>
                                                                        {item.label}
                                                                    </Select.Item>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormField>
                                                </Layout.Item>
                                                <Layout.Item default="1/2" s="1/1" className={'u-pl-none@s'}>
                                                    <ValidatedInput
                                                        label={t('mobile-phone-digits')}
                                                        name="mobilePhone"
                                                        testId="mobile-phone"
                                                        addonText={`+${prefixMobilePhone}`}
                                                        reversed
                                                        type="tel"
                                                        handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            handlerChangeInput(e)
                                                        }
                                                    />
                                                </Layout.Item>
                                            </Layout.Item>
                                            <Layout.Item default="1/2" s="1/1">
                                                <Layout.Item default="1/1" className={'u-pl-none'}>
                                                    <h5>{t('fixed-phone')}</h5>
                                                </Layout.Item>
                                                <Layout.Item default="1/2" s="1/1" className={'u-pl-none'}>
                                                    <FormField type="select" labelText={t('mobile-phone-country-code')}>
                                                        <Select
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                handlerChangeAreaCodeMainPhone(e, formik.setFieldValue)
                                                            }
                                                            defaultValue={prefixMainPhone}
                                                            id="prefixMainPhone"
                                                            testId="prefixMainPhone"
                                                        >
                                                            {<Select.Item hidden />}
                                                            {optionsFromCountryMapping().map((item, index) => {
                                                                return (
                                                                    <Select.Item key={index} value={item.value}>
                                                                        {item.label}
                                                                    </Select.Item>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormField>
                                                </Layout.Item>
                                                <Layout.Item default="1/2" s="1/1" className={'u-pl-none@s'}>
                                                    <ValidatedInput
                                                        label={t('fixed-phone-digits')}
                                                        name="mainPhone"
                                                        testId="main-phone"
                                                        reversed
                                                        addonText={`${!prefixMainPhone ? ' ' : `+${prefixMainPhone}`}`}
                                                        type="tel"
                                                        handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            handlerChangeInput(e)
                                                        }
                                                    />
                                                </Layout.Item>
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                </Fieldset>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ButtonContainer center>
                                            <Button
                                                secondary
                                                onClick={() => {
                                                    cancelEditing(formValuesState);
                                                }}
                                                testId="cancel-button"
                                                type="button"
                                            >
                                                {t('contact-details.back-button')}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    formik.handleSubmit();
                                                    const initialErrors = getInitialErrors(formik.values);
                                                    if (!isEmpty(formik.errors)) {
                                                        const errorsList = getErrors(formik.errors);
                                                        onValidationError(errorsList);
                                                    } else if (!!initialErrors) {
                                                        onValidationError(initialErrors);
                                                    }
                                                }}
                                                testId="submit-button"
                                                type="button"
                                            >
                                                {t('contact-details.confirm-button')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                </UiBlockingSpinner>
            </>
        </DataOverview>
    );
};
