import { Contract, formatAsCurrency, PayOffProposal, TotalEarlySettlement } from '@cp-nl/common';
import { formatCpDate } from '@cp-shared-7/common-utilities';
import { DataOverview, Notification, NotificationStatus } from '@cp-shared-7/frontend-ui';
import { Button, ButtonContainer, DescriptionList, ProductTable, Heading, Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type OfflinePaymentProps = {
    totalEarlySettlementCMS?: TotalEarlySettlement;
    backButtonClick: () => void;
    dashboardButtonClick: () => void;
    printButtonClick: () => void;
    payOffProposal?: PayOffProposal;
    contract: Contract;
};

export const OfflinePayment: React.FC<OfflinePaymentProps> = ({
    totalEarlySettlementCMS,
    backButtonClick,
    dashboardButtonClick,
    printButtonClick,
    payOffProposal,
    contract,
}) => {
    const { t } = useTranslation('total-early-settlement');

    return (
        <Layout>
            <Layout.Item>
                <Heading level={2}>{totalEarlySettlementCMS?.paymentDetails.headline}</Heading>
                {totalEarlySettlementCMS?.paymentDetails.text}
            </Layout.Item>
            <Layout.Item>
                <DataOverview title={t('bank-transfer-details.headline')}>
                    <Layout divider className="row">
                        <Layout.Item default="1/2">
                            <DescriptionList>
                                <DescriptionList.Detail termText={t('bank-transfer-details.beneficiary-name')}>
                                    {payOffProposal?.beneficiaryName}
                                </DescriptionList.Detail>
                                <DescriptionList.Detail termText={t('bank-transfer-details.beneficiary-iban')}>
                                    {payOffProposal?.beneficiaryIBAN}
                                </DescriptionList.Detail>
                                <DescriptionList.Detail termText={t('bank-transfer-details.reference-number')}>
                                    {contract.contractNumber}
                                </DescriptionList.Detail>
                            </DescriptionList>
                        </Layout.Item>
                        <Layout.Item default="1/2">
                            <ProductTable.Section className={'u-text-uppercase'}>
                                <DescriptionList.Detail
                                    termText={
                                        <Heading level={4} className={'u-m-none'}>
                                            {t('bank-transfer-details.payoff-amount')}
                                        </Heading>
                                    }
                                >
                                    {formatAsCurrency(payOffProposal?.payOffAmount)}
                                </DescriptionList.Detail>
                                <DescriptionList.Detail
                                    termText={
                                        <Heading level={5} className={'u-m-none'}>
                                            {t('bank-transfer-details.calculation-date')}
                                        </Heading>
                                    }
                                >
                                    {formatCpDate(payOffProposal?.calculationDate).format('DD-MM-YY')}
                                </DescriptionList.Detail>
                            </ProductTable.Section>
                        </Layout.Item>
                    </Layout>
                </DataOverview>
            </Layout.Item>
            <Layout.Item>
                <Notification status={NotificationStatus.warning}>
                    <>
                        <span>{payOffProposal?.paymentDescription}</span>
                        <br />
                        <span>{payOffProposal?.handlingInformation}</span>
                    </>
                </Notification>
            </Layout.Item>
            <Layout.Item>
                <ButtonContainer center>
                    <Button secondary onClick={backButtonClick} testId="backButton" type="btn">
                        {t('back-button')}
                    </Button>
                    <Button onClick={dashboardButtonClick} testId="dashboardButton" type="btn">
                        {t('dashboard-button')}
                    </Button>
                    <Button onClick={printButtonClick} link={true} testId="printButton" type="btn">
                        {t('print-button')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </Layout>
    );
};
