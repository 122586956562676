import { Contract, ContractsCms } from '@cp-nl/common';
import { NotificationStatus } from '@cp-shared-7/frontend-ui';
import { Button, Notification } from '@vwfs-bronson/bronson-react';
import { myRequestPagePathWithContractId, unpaidBalancePagePath } from 'components/navigation/paths';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

export type ContractCardUnpaidBalanceProps = {
    contract: Contract;
    content?: ContractsCms;
};

export const ContractUnpaidBalance: React.FC<ContractCardUnpaidBalanceProps> = ({ contract, content }) => {
    const history = useHistory();

    if (!contract.unpaidBalance?.unpaidPresent) return null;
    if (!content) return null;

    const handleClick = () => history.push(unpaidBalancePagePath(contract.encryptedContractVersionId));

    const notificationText = (
        <span>
            {content.unpaidBalance.text}
            <Link to={myRequestPagePathWithContractId(contract.encryptedContractVersionId)}>
                {content.unpaidBalance.linkText}
            </Link>
        </span>
    );

    return (
        <Notification
            status={NotificationStatus.warning}
            title={content?.unpaidBalance.headline}
            className={'u-mb'}
            visible
            showCloseButton={false}
            buttons={[
                <Button key="1" secondary className="u-bg-white u-mt" onClick={handleClick}>
                    {content?.unpaidBalance.button}
                </Button>,
            ]}
        >
            {notificationText}
        </Notification>
    );
};
