import { Contract } from '@cp-nl/common';

export type GroupedContractsByVin = Array<Contract[]>;

export const groupContractsByVin = (contracts: Contract[]): GroupedContractsByVin => {
    return contracts.reduce((acc: GroupedContractsByVin, current) => {
        const groupIndex = acc.findIndex((group) => group.some((contract) => contract.vinCode === current.vinCode));
        if (groupIndex > -1) {
            acc[groupIndex].push(current);
        } else {
            acc.push([current]);
        }
        return acc;
    }, []);
};
