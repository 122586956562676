import ibanLengths from './IbanLength';

export const stripWhitespaces = (iban?: string | null): string => iban?.replace(/\s/g, '') ?? '';

export const getIbanNumber = (iban: string): string => stripWhitespaces(iban).substr(2);

const getCountryCodeFromIban = (iban: string): string => stripWhitespaces(iban).substr(0, 2);

const selectValidLength = (iban: string): number => {
    const countryCode = getCountryCodeFromIban(iban);
    return countryCode in ibanLengths ? ibanLengths[countryCode] : Math.max(...Object.values(ibanLengths));
};

export const isValidLength = (iban: string): boolean =>
    iban ? stripWhitespaces(iban).length === selectValidLength(iban) : false;

export const isValidCountryCode = (iban?: string): boolean =>
    iban ? getCountryCodeFromIban(iban) in ibanLengths : false;
