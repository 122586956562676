import { Contract, formatAsCurrency, formatAsDate } from '@cp-nl/common';
import { ContractSummary } from '@cp-shared-7/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ContractCardHeaderProps = {
    contract: Contract;
};

export const ContractCardHeader: React.FC<ContractCardHeaderProps> = ({ contract }) => {
    const { t } = useTranslation('contracts');
    const { nextDueDate, nextDueAmount, outstandingBalance } = contract;

    return (
        <ContractSummary
            items={[
                {
                    label: t('next-due-date'),
                    testClass: 'next-due-date',
                    value: formatAsDate(nextDueDate) || '-',
                },
                {
                    label: t('next-due-amount'),
                    testClass: 'next-due-amount',
                    value: formatAsCurrency(nextDueAmount) || '-',
                },
                ...(outstandingBalance !== -1
                    ? [
                          {
                              label: t('outstanding-capital'),
                              testClass: 'outstanding-balance',
                              value: formatAsCurrency(outstandingBalance) || '-',
                              tooltip: t('tooltip-outstanding-capital'),
                          },
                      ]
                    : []),
            ]}
        />
    );
};
