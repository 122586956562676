import { RequestLoadingPlaceholder } from '@cp-shared-7/frontend-ui';
import { useContracts } from 'components/contracts/useContracs';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { RequestUi } from './ui/RequestUi';
import { useMyRequest } from './useMyRequest';

const RequestWithHandlers = withLoadingAndNoConnectionHandler(RequestUi);

type RequestProps = {
    unpaidBalanceContract?: string;
};

export const Request: React.FC<RequestProps> = ({ unpaidBalanceContract }) => {
    const { cmsContent: myRequest } = useMyRequest();
    const { data, isLoading, loadingError } = useContracts();

    return (
        <RequestWithHandlers
            myRequest={myRequest}
            isLoading={isLoading}
            contracts={data?.contracts}
            hasError={
                loadingError?.code === 'MARKET_API_DEFAULT_BUSINESS_ERROR' ||
                loadingError?.code === 'CP_API_NOT_REACHABLE'
            }
            unpaidBalanceContract={unpaidBalanceContract}
            loadingPlaceholder={<RequestLoadingPlaceholder withTextArea numberOfButtons={2} />}
        />
    );
};
