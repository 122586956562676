import { createCmsContentSlice } from 'cms-integration';
import { getOtherBrandsAvailableModal, OtherBrandsAvailableModal } from '@cp-nl/common';

const { reducer, fetchContent } = createCmsContentSlice<OtherBrandsAvailableModal>({
    contentName: 'otherBrandsAvailableModal',
    contentEndpoint: getOtherBrandsAvailableModal,
});

export default reducer;
export const fetchOtherBrandsAvailableModal = fetchContent;
