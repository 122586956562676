import { BankDetailsSectionContentCms, getIbanChangeEndpoint, IbanChange } from '@cp-nl/common';
import {
    CleaveInput,
    DataOverview,
    InfoIcon,
    NotificationStatus,
    preventSubmit,
    Spinner,
    UiBlockingSpinner,
    ValidatedInput,
    ValidatedTextarea,
} from '@cp-shared-7/frontend-ui';
import {
    Button,
    ButtonContainer,
    Fieldset,
    Form,
    Layout,
    RadioButton,
    RadioButtonGroup,
    Notification,
} from '@vwfs-bronson/bronson-react';
import { validationSchema } from 'components/my-profile/bank-details-section/edit-view/validationSchema';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { myProfilePagePath } from '../../../navigation/paths';
import { IbanNotificationType, IbanStateHandler } from './validated-iban-input/types';

export type BankDetailsEditViewProps = {
    cmsContent: BankDetailsSectionContentCms;
    onCancel: () => void;
    ibanStateHandler: IbanStateHandler;
    setNotificationType: (type: IbanNotificationType) => void;
    setIban: (iban: string) => void;
    currentIban: string;
};

export type FormValues = {
    iban: string;
    isSameName: string;
    ibanName: string;
    requestText: string;
};

export const BankDetailsEditView: React.FC<BankDetailsEditViewProps> = ({
    ibanStateHandler,
    onCancel,
    cmsContent,
    setNotificationType,
    currentIban,
    setIban,
}) => {
    const [notSameName, setNotSameName] = useState(false);
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isConnectionError, setIsConnectionError] = useState(false);

    const history = useHistory();

    const { signedBankData } = ibanStateHandler;

    const initialValues: FormValues = {
        iban: '',
        isSameName: '',
        ibanName: '',
        requestText: '',
    };

    const handleSubmit = (values: FormValues): void => {
        setIsSubmitting(true);
        const preparedChange: IbanChange = {
            iban: values.iban.replace(/\s/g, ''),
            isForeignIBAN: !(values.iban.slice(0, 2) === 'NL'),
            notSameName,
            ibanName: values.ibanName,
            requestText: values.requestText,
        };

        CpDataApi.put(getIbanChangeEndpoint(), preparedChange)
            .then((response) => {
                switch (response?.data?.ElementName) {
                    case 'ibanChangeSuccesful':
                        setIban(preparedChange.iban);
                        setNotificationType('Success');
                        break;
                    case 'ManualChange':
                        setNotificationType('ManualChange');
                        break;
                    case 'Required2factor':
                        setNotificationType('2Factor');
                        break;
                    default:
                        break;
                }
                setIsSubmitting(false);
                history.push(myProfilePagePath());
            })
            .catch(() => {
                setIsSubmitting(false);
                setIsConnectionError(true);
            });
    };

    return (
        <DataOverview title={cmsContent.headline}>
            {isSubmitting && <Spinner fullPage={true} />}
            <Notification status={NotificationStatus.info} testId="infoNotification" visible showCloseButton={false}>
                {cmsContent.notification.info}
            </Notification>
            {isConnectionError && (
                <Notification
                    status={NotificationStatus.error}
                    title={cmsContent.notification.error.headline}
                    className="u-mt-small"
                    testId="connectionErrorNotification"
                    visible
                    showCloseButton={false}
                >
                    {cmsContent.notification.error.text}
                </Notification>
            )}
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema(
                    cmsContent,
                    notSameName,
                    setIsValidating,
                    ibanStateHandler,
                    currentIban,
                )}
                validateOnBlur={false}
            >
                {(formik) => (
                    <Form onSubmit={preventSubmit()} className="u-mt">
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/2" s="1/1">
                                        <UiBlockingSpinner isBlocking={isValidating}>
                                            <CleaveInput
                                                cleaveOptions={{
                                                    delimiter: ' ',
                                                    blocks: [4, 4, 4, 4, 4, 4, 4, 4],
                                                    numericOnly: false,
                                                }}
                                                label={cmsContent.form.newIBANInputLabel}
                                                name="iban"
                                                testId="iban"
                                                stateIcon
                                                tooltip={cmsContent.form.newIBANInputTooltip}
                                                handleChange={() => formik.setTouched({ iban: true }, true)}
                                            />
                                            {signedBankData?.data.isValid && (
                                                <p className="u-text-success">
                                                    {signedBankData.data.bankDetails?.bankName}
                                                </p>
                                            )}
                                        </UiBlockingSpinner>
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item>
                                        <p>
                                            {cmsContent.form.notSameName.label}
                                            <InfoIcon text={cmsContent.form.notSameName.tooltip} />
                                        </p>

                                        <RadioButtonGroup>
                                            <RadioButton
                                                name="isSameName"
                                                value={cmsContent.form.notSameName.yes}
                                                testId="iSameNameYes"
                                                defaultChecked={true}
                                                onChange={() => {
                                                    formik.setFieldValue('isSameName', cmsContent.form.notSameName.yes);
                                                    setNotSameName(false);
                                                }}
                                            >
                                                {cmsContent.form.notSameName.yes}
                                            </RadioButton>
                                            <RadioButton
                                                name="isSameName"
                                                value={cmsContent.form.notSameName.no}
                                                testId="iSameNameNo"
                                                onChange={() => {
                                                    formik.setFieldValue('isSameName', cmsContent.form.notSameName.no);
                                                    setNotSameName(true);
                                                }}
                                            >
                                                {cmsContent.form.notSameName.no}
                                            </RadioButton>
                                        </RadioButtonGroup>
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            {notSameName && (
                                <>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/2" s="1/1">
                                                <ValidatedInput
                                                    name="ibanName"
                                                    label={cmsContent.form.IBANName.label}
                                                    testId="ibanNameInput"
                                                    type="text"
                                                    tooltip={cmsContent.form.IBANName.tooltip}
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/2" s="1/1">
                                                <ValidatedTextarea
                                                    name="requestText"
                                                    label={cmsContent.form.requestText.label}
                                                    testId="requestTextInput"
                                                    maxLength={200}
                                                    enableMaxLength={true}
                                                    rows={4}
                                                    isMandatory={true}
                                                    tooltip={cmsContent.form.requestText.tooltip}
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                </>
                            )}
                            <Fieldset.Row>
                                <ButtonContainer center>
                                    <Button secondary onClick={onCancel} testId="backButton">
                                        {cmsContent.form.backButton}
                                    </Button>
                                    <Button onClick={formik.handleSubmit} type="submit" testId="submitButton">
                                        {cmsContent.form.submitButton}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </DataOverview>
    );
};
