import { Contract, ContractsCms } from '@cp-nl/common';
import { ContractActions } from '@cp-shared-7/frontend-ui';
import {
    contractHandoverPagePath,
    postboxPagePathWithContractId,
    totalEarlySettlementPagePath,
} from 'components/navigation/paths';
import { currentBrand } from 'config';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ContractActionItemsProps = {
    contract: Contract;
    content?: ContractsCms;
};

export const ContractActionItems: React.FC<ContractActionItemsProps> = ({ contract, content }) => {
    const { t } = useTranslation('contracts');
    const { encryptedContractVersionId, contractNumber } = contract;

    const actionItems = [
        {
            iconName: 'semantic-download',
            pageUrl: postboxPagePathWithContractId(encryptedContractVersionId),
            label: t('actions.postbox'),
            tooltip: '',
        },
    ];

    if (contract.contractStatus === 'ACTIVE') {
        currentBrand !== 'df' &&
            actionItems.push({
                iconName: 'semantic-file',
                pageUrl: contractHandoverPagePath(contractNumber),
                label: content?.contractHandover.label || '',
                tooltip: content?.contractHandover.tooltip || '',
            });

        if (!contract.unpaidBalance?.unpaidPresent) {
            actionItems.push({
                iconName: 'semantic-calculator',
                pageUrl: totalEarlySettlementPagePath(encryptedContractVersionId),
                label: t('actions.total-early-settlement'),
                tooltip: '',
            });
        }
    }

    return (
        <div className="u-pb-small u-mt-xxsmall">
            <ContractActions contractActionItems={actionItems} />
        </div>
    );
};
