import React from 'react';

import { RootState } from 'store/rootReducer';
import { useCmsContent } from '@cp-shared-7/frontend-integration';
import { fetchFaq as fetchPrivateFaq } from './FaqPrivateSlice';
import { FaqWithHandlers } from './FaqView';
import { FaqLoadingPlaceholder } from '@cp-shared-7/frontend-ui';

export const FaqPrivate: React.FC = () => {
    const { cmsContent: faq, isLoading, loadingError } = useCmsContent(fetchPrivateFaq, (state: RootState) => {
        return state.content.faqPrivate;
    });

    return (
        <FaqWithHandlers
            hasError={!!loadingError}
            isLoading={isLoading}
            faq={faq}
            loadingPlaceholder={<FaqLoadingPlaceholder withDescription />}
        />
    );
};
