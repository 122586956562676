import { Address, ChangeAddress, getAddressChangeEndpoint } from '@cp-nl/common';
import {
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    ValidatedInput,
} from '@cp-shared-7/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditStatus } from '../../../address-view/NotificationForEditStatus';
import { validationSchema } from '../validationSchema';
import { isEmpty } from 'lodash';

type ChangeAddressProps = {
    formValues: ChangeAddress;
    onCancel: (values?: ChangeAddress) => void;
    finishEditing: (newEditStatus: EditStatus, updatedAddress?: Address) => void;
    onEdit: (addressType: string) => void;
    addressType?: string;
    address?: Address;
};

export const AddressFormNotNL: React.FC<ChangeAddressProps> = ({
    formValues,
    onCancel,
    addressType,
    finishEditing,
    address,
}) => {
    const { t } = useTranslation('change-address');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileAddressValidationError');
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, 'Address');
    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onEditProfileAddressTypedIn',
    });
    const getInitialErrors = (values: { [k: string]: string }) =>
        ['postCode', 'houseNumber', 'street', 'locality'].filter((element) => values[element] === '').join(', ');
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);

    const [formValuesState, setFormValuesState] = useState<ChangeAddress>({
        country: formValues?.country || '',
        postCode: formValues?.postCode || '',
        houseNumber: formValues?.houseNumber || '',
        door: formValues?.door || '',
        street: formValues?.street || '',
        locality: formValues?.locality || '',
        extraInfo: formValues?.extraInfo || '',
    });

    const handleSubmit = () => {
        const preparedValues: ChangeAddress = {
            country: formValuesState.country,
            postCode: formValuesState.postCode,
            houseNumber: formValuesState.houseNumber,
            door: formValuesState.door,
            street: formValuesState.street,
            locality: formValuesState.locality,
            extraInfo: formValuesState.extraInfo,
            addressType: addressType,
            noAddressCheck: true,
        };

        setIsSubmitting(true);

        CpDataApi.put(getAddressChangeEndpoint(), preparedValues)
            .then((response) => {
                const preparedChanges: Address =
                    addressType === 'living address'
                        ? {
                              visitAddressCountry: preparedValues.country,
                              visitAddressLine1: `${preparedValues.street} ${preparedValues.houseNumber} ${preparedValues.door}`,
                              visitAddressLine2: `${preparedValues.postCode} ${preparedValues.locality}`,
                              postalAddressCountry: address?.postalAddressCountry,
                              postalAddressLine1: address?.postalAddressLine1,
                              postalAddressLine2: address?.postalAddressLine2,
                          }
                        : {
                              postalAddressCountry: preparedValues.country,
                              postalAddressLine1: `${preparedValues.street} ${preparedValues.houseNumber} ${preparedValues.door}`,
                              postalAddressLine2: `${preparedValues.postCode} ${preparedValues.locality}`,
                              visitAddressCountry: address?.visitAddressCountry,
                              visitAddressLine1: address?.visitAddressLine1,
                              visitAddressLine2: address?.visitAddressLine2,
                          };

                const updatedAddress = preparedChanges;

                const result = response.data[0].ElementName;
                if (result === 'addressChangeSuccessful') {
                    finishEditing(EditStatus.SUCCESS, updatedAddress);
                } else if (result === 'ForeignChange') {
                    finishEditing(EditStatus.FOREIGN_CHANGE);
                } else if (result === 'ManualChange') {
                    finishEditing(EditStatus.MANUAL_CHANGE);
                }
            })
            .catch(() => {
                finishEditing(EditStatus.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handlerChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
        setFormValuesState({
            ...formValuesState,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}
            <Formik initialValues={formValues} onSubmit={handleSubmit} validationSchema={validationSchema(t)}>
                {(formik) => (
                    <Form
                        onSubmit={preventSubmit()}
                        onChange={() => onTyping(formik.errors, formik.touched)}
                        data-testid="addressChangeFormNotNL"
                    >
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/2">
                                        <ValidatedInput
                                            label={t('form.street')}
                                            name="street"
                                            testId="street"
                                            type="text"
                                            handleChange={(e: ChangeEvent<HTMLInputElement>) => handlerChangeInput(e)}
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/4">
                                        <ValidatedInput
                                            label={t('form.house-number')}
                                            name="houseNumber"
                                            testId="houseNumber"
                                            type="text"
                                            handleChange={(e: ChangeEvent<HTMLInputElement>) => handlerChangeInput(e)}
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/4">
                                        <ValidatedInput
                                            label={t('form.door')}
                                            name="door"
                                            testId="door"
                                            type="text"
                                            handleChange={(e: ChangeEvent<HTMLInputElement>) => handlerChangeInput(e)}
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/2">
                                        <ValidatedInput
                                            label={t('form.post-code')}
                                            name="postCode"
                                            testId="postCode"
                                            type="text"
                                            handleChange={(e: ChangeEvent<HTMLInputElement>) => handlerChangeInput(e)}
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/2">
                                        <ValidatedInput
                                            label={t('form.locality')}
                                            name="locality"
                                            testId="locality"
                                            type="text"
                                            handleChange={(e: ChangeEvent<HTMLInputElement>) => handlerChangeInput(e)}
                                            autocomplete="locality"
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/1">
                                        <ValidatedInput
                                            label={t('form.additional-information')}
                                            name="additionalInformation"
                                            testId="additionalInformation"
                                            type="text"
                                            handleChange={(e: ChangeEvent<HTMLInputElement>) => handlerChangeInput(e)}
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer center className="u-mt">
                                    <Button onClick={onCancel} secondary testId="cancelButton">
                                        {t('form.cancel-button')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            formik.handleSubmit();
                                            const initialErrors = getInitialErrors({
                                                postCode: formik.values.postCode,
                                                houseNumber: formik.values.houseNumber,
                                                street: formik.values.street,
                                                locality: formik.values.locality,
                                            });
                                            if (!isEmpty(formik.errors)) {
                                                const errorsList = getErrors(formik.errors);
                                                onValidationError(errorsList);
                                            } else if (!!initialErrors) {
                                                onValidationError(initialErrors);
                                            }
                                        }}
                                        testId="submitButton"
                                        type="button"
                                    >
                                        {t('form.submit-button')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </>
    );
};
