import { useTranslation } from 'react-i18next';
import { DropDownItem } from '@cp-shared-7/frontend-ui';
import { useSortedContractsCount } from './useContractsCount';
import { getCurrentStage } from '../../../utils';
import { dashboardPagePath } from '../paths';
import urls from '../../../config/urls';
import { currentBrand } from '../../../config';

const brandUrls = urls[getCurrentStage()];

export function useContractsCountDropdown(): DropDownItem[] {
    const { t } = useTranslation('brands');
    const { data, isLoading, loadingError } = useSortedContractsCount();

    if (isLoading || loadingError || !data) {
        return [];
    }

    return data.map(({ brand, numberOfContracts }) => ({
        url: brand === currentBrand ? dashboardPagePath() : `${brandUrls[brand]}${dashboardPagePath()}`,
        label: `${t(brand)} (${numberOfContracts})`,
    }));
}
