import { formatCpDate } from '@cp-shared-7/common-utilities';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const openVerificationValidationSchema = (t: TFunction): Yup.ObjectSchema => {
    return Yup.object().shape({
        contractNumber: Yup.string().required(t('form.validation.contract-number-required')),
        licensePlate: Yup.string().required(t('form.validation.license-plate-required')),
        initials: Yup.string().required(t('form.validation.initials-required')),
        lastName: Yup.string().required(t('form.validation.last-name-required')),
        birthday: Yup.string()
            .required(t('form.validation.birthday-required'))
            .test('format', t('form.validation.birthday-invalid-format'), (date) =>
                formatCpDate(date, 'DD.MM.YYYY').isValid(),
            ),
    });
};
