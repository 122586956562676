import * as Yup from 'yup';
import { SmsFormValidation } from '@cp-nl/common';

export const identificationFormValidationSchema = (content: SmsFormValidation) => {
    return Yup.object().shape({
        smsCode: Yup.string().trim().required(content.emptyCode),
        privacyPolicy: Yup.bool().oneOf([true], content.privacyPolicy),
        termsAndCondition: Yup.bool().oneOf([true], content.termsAndCondition),
    });
};
