import { ContactDetails } from '@cp-nl/common';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-7/frontend-ui';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeContactDetailsPath } from '../../navigation/paths';
import { NoConnectionNotification } from '../../notifications/no-connection/NoConnectionNotification';
import { EditStatus, EditView } from './edit-view/EditView';
import { NonEditView } from './non-edit-view/NonEditView';
import { ContactDetailsChangeForm } from './types';

export type EditStatusWithData = {
    status: EditStatus;
    contactDetails?: ContactDetails;
    formValues?: ContactDetailsChangeForm;
};

export const ContactSection: React.FC<{ contactDetails?: ContactDetails }> = ({ contactDetails }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { t } = useTranslation('my-profile');
    const [editStatus, setEditStatus] = useState<EditStatusWithData>({
        status: EditStatus.NOT_PERFORMED,
        contactDetails: contactDetails,
    });

    const { onAction: onEdit } = useAnalyticsActionTracker('onEditProfileContact');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');

    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileContactSubmitFailed',
        confirmSuccess: 'onEditProfileContactSuccess',
    });

    const startEditing = (): void => {
        onEdit();
        setEditStatus({
            ...editStatus,
            status: EditStatus.NOT_PERFORMED,
        });
        history.push(changeContactDetailsPath());
    };

    const cancelEditing = (formValue?: ContactDetailsChangeForm): void => {
        onCancel();
        setEditStatus({
            ...editStatus,
            status: EditStatus.NOT_PERFORMED,
            formValues: formValue,
        });
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus, updatedContact?: ContactDetails): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onError();
        setEditStatus({
            ...editStatus,
            status: newEditStatus,
            contactDetails: newEditStatus === EditStatus.SUCCESS ? updatedContact : contactDetails,
        });

        history.push(path);
    };

    if (!contactDetails) {
        return (
            <DataOverview title={t('contact-details.sub-headline')}>
                <NoConnectionNotification />
            </DataOverview>
        );
    }

    return (
        <Switch>
            <Route exact path={changeContactDetailsPath()}>
                <EditView
                    contactDetails={editStatus.contactDetails}
                    formValues={editStatus.formValues}
                    cancelEditing={cancelEditing}
                    finishEditing={finishEditing}
                />
            </Route>
            <Route path={path}>
                <NonEditView
                    contactDetails={editStatus.contactDetails}
                    startEditing={startEditing}
                    editStatus={editStatus.status}
                />
            </Route>
        </Switch>
    );
};
