import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { currentBrand, idpHint } from '../../config';
import { useNavigationBar } from './useNavigationBar';
import { useContractsCountDropdown } from './contracts-count/useContractsCountDropdown';

import {
    DropDownItem,
    NavigationBar as NavigationBarShared,
    SiteNavItemPropsList,
    SiteNavProps,
    useAnalyticsActionTracker,
    useAuthentication,
} from '@cp-shared-7/frontend-ui';

import {
    cookiePolicyPath,
    dashboardPagePath,
    faqPagePath,
    identificationPagePath,
    landingPagePath,
    legalNoticePath,
    loginInProgressPagePath,
    myProfilePagePath,
    myRequestPagePath,
    notAuthorizedPagePath,
    forbiddenPagePath,
    notFoundPagePath,
    postboxPagePath,
    registrationPagePath,
} from './paths';

const pagesWithoutNavbar = [
    registrationPagePath(),
    notAuthorizedPagePath(),
    identificationPagePath(),
    forbiddenPagePath(),
];

const pagesWithoutStaticHeader = [
    landingPagePath(),
    faqPagePath(),
    cookiePolicyPath(),
    legalNoticePath(),
    notAuthorizedPagePath(),
    notFoundPagePath(),
    registrationPagePath(),
];

export type NavigationBarProps = {
    isAuthenticated?: boolean;
};

function publicNavigationLinks(t: TFunction, login: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: registrationPagePath(),
            label: t('navigation.register'),
        },
        {
            label: t('navigation.login'),
            onClick: login,
        },
    ];
}

function privateNavigationLinks(
    t: TFunction,
    logout: () => Promise<void>,
    dashboardDropdownItems: DropDownItem[],
): SiteNavItemPropsList {
    return [
        {
            url: dashboardDropdownItems.length ? undefined : dashboardPagePath(),
            label: t('navigation.dashboard'),
            dropdownItems: dashboardDropdownItems.length ? dashboardDropdownItems : undefined,
        },
        {
            url: myProfilePagePath(),
            label: t('navigation.profile'),
        },
        {
            url: myRequestPagePath(),
            label: t('navigation.my-request'),
        },
        {
            url: postboxPagePath(),
            label: t('navigation.postbox'),
        },
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.logout'),
            onClick: logout,
        },
    ];
}

export const NavigationBar: React.FC<NavigationBarProps> = () => {
    const { t, i18n } = useTranslation('navigation');
    const { isAuthenticated, logout, login } = useAuthentication();
    const { cmsContent: navigationBar } = useNavigationBar();
    const currentPathName = useLocation().pathname;
    const language = i18n.languages[0];
    const history = useHistory();
    const location = useLocation();
    const currentPageName = location.pathname.split('/')[1];
    const { onAction: onLogin } = useAnalyticsActionTracker('login');
    const { onAction: onLogout } = useAnalyticsActionTracker('logout');
    const dashboardDropdownItems = useContractsCountDropdown();

    const navigationItems = (isAuthenticated
        ? privateNavigationLinks(
              t,
              () => {
                  onLogout(currentPageName);
                  return logout({ redirectUri: window.location.origin + landingPagePath() });
              },
              dashboardDropdownItems,
          )
        : publicNavigationLinks(t, () => {
              onLogin(currentPageName);
              return login({
                  redirectUri: window.location.origin + loginInProgressPagePath(),
                  locale: language,
                  idpHint,
                  prompt: 'login',
              });
          })
    ).map((navItem) => ({
        ...{ isActive: history.location.pathname === navItem.url },
        ...navItem,
    }));

    const hidden = pagesWithoutNavbar.includes(currentPathName);
    const withStaticHeader = !pagesWithoutStaticHeader.includes(currentPathName);
    const navigation: SiteNavProps = {
        navigationItems,
    };
    const NavigationBarSharedWithLogo =
        currentBrand === 'df' ? (
            <NavigationBarShared
                logoAltText={isAuthenticated ? t('navigation.dashboard') : t('navigation.home')}
                navigation={navigation}
                withStaticHeader={withStaticHeader}
                hidden={hidden}
                logoSource={navigationBar && navigationBar.logoHeaderUrl}
                logoClassName={'u-2/3 u-mt-small  u-3/3@s u-mt@s'}
                onLogoClickPath={dashboardPagePath()}
            />
        ) : (
            <NavigationBarShared
                logoAltText={isAuthenticated ? t('navigation.dashboard') : t('navigation.home')}
                navigation={navigation}
                withStaticHeader={withStaticHeader}
                hidden={hidden}
                openMenuText={t('navigation.menu')}
                onLogoClickPath={dashboardPagePath()}
                closeMenuText={t('navigation.close-menu')}
            />
        );

    return NavigationBarSharedWithLogo;
};
