import React from 'react';
import { Button, Card, Link } from '@vwfs-bronson/bronson-react';
import { useAnalyticsActionTracker } from '@cp-shared-7/frontend-ui';
import { BrokerDetails, DashboardMarketingCard } from '@cp-nl/common';
import { currentBrand } from 'config';
import { getLinkWithTraciParams } from './utils';
import { getCurrentStage } from 'utils';

export type DashboardMarketingCardUiProps = {
    dashboardMarketingCard?: DashboardMarketingCard;
    brokerDetails?: BrokerDetails;
};

export const DashboardMarketingCardUi: React.FC<DashboardMarketingCardUiProps> = ({
    dashboardMarketingCard,
    brokerDetails,
}) => {
    const { onAction } = useAnalyticsActionTracker('onLearnMore');

    if (!dashboardMarketingCard) return null;

    const { carInsurance, brokerDetails: brokerDetailsCms } = dashboardMarketingCard;
    const currentStage = getCurrentStage();

    const handleClick = (): void => {
        const link = getLinkWithTraciParams(
            carInsurance.buttonUrl[currentStage],
            currentBrand,
            carInsurance.buttonLabel,
        );
        window.open(link, '_blank');
        onAction(carInsurance.title);
    };

    return (
        <>
            {brokerDetails && (
                <Card className="u-mb u-text-left" element="article" testid="brokerDetailsCard">
                    {(currentBrand === 'seat' || currentBrand === 'vwcv' || currentBrand === 'vw') && (
                        <>
                            <h5 className={'u-heading-alternative u-hide@l u-mb'}>{brokerDetailsCms.title}</h5>
                            <h6 className={'u-heading-alternative u-hide@l-up u-mb'}>{brokerDetailsCms.title}</h6>
                            <h5 className={'u-heading-alternative u-hide@l u-mb'}>{brokerDetails.companyName}</h5>
                            <h6 className={'u-heading-alternative u-hide@l-up u-mb'}>{brokerDetails.companyName}</h6>
                        </>
                    )}
                    {currentBrand !== 'seat' && currentBrand !== 'vwcv' && currentBrand !== 'vw' && (
                        <>
                            <h3 className={'u-heading-alternative u-hide@l u-mb'}>{brokerDetailsCms.title}</h3>
                            <h4 className={'u-heading-alternative u-hide@l-up u-mb'}>{brokerDetailsCms.title}</h4>
                            <h3 className={'u-heading-alternative u-hide@l u-mb'}>{brokerDetails.companyName}</h3>
                            <h4 className={'u-heading-alternative u-hide@l-up u-mb'}>{brokerDetails.companyName}</h4>
                        </>
                    )}
                    <p>
                        {`${brokerDetails.streetName} ${brokerDetails.streetNumber}`}
                        <br /> {`${brokerDetails.zipCode} ${brokerDetails.city}`}
                    </p>
                    <Link href={`tel:${brokerDetails.mainPhone}`} normalLink>
                        {brokerDetails.mainPhone}
                    </Link>
                    <br />
                    {brokerDetails.mobilePhone && (
                        <>
                            <Link href={`tel:${brokerDetails.mobilePhone}`} normalLink>
                                {brokerDetails.mobilePhone}
                            </Link>
                            <br />
                        </>
                    )}
                    <Link href={`mailto:${brokerDetails.email}`} normalLink>
                        {brokerDetails.email}
                    </Link>
                    <br />
                    <Link href={`https://${brokerDetails.website}`} normalLink target="_blank">
                        {brokerDetails.website}
                    </Link>
                </Card>
            )}
            {carInsurance.title && (
                <Card
                    element="article"
                    imageSrc={carInsurance.imageUrl || ''}
                    footer
                    buttons={[
                        <Button
                            key={'buttonKey'}
                            link={true}
                            icon="semantic-forward"
                            iconReversed
                            small
                            onClick={handleClick}
                        >
                            {carInsurance.buttonLabel}
                        </Button>,
                    ]}
                >
                    <h4 className={'u-heading-alternative u-hide@m u-mb'}>{carInsurance.title}</h4>
                    <h3 className={'u-heading-alternative u-hide@m-up u-mb'}>{carInsurance.title}</h3>
                    {carInsurance.text}
                </Card>
            )}
        </>
    );
};
