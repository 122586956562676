import React, { useEffect, useState } from 'react';
import { useTotalEarlySettlementCms } from './useTotalEarlySettlementCms';
import { TotalEarlySettlementUi } from './ui/TotalEarlySettlementUi';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useContract } from 'components/contracts/useContract';
import { CpDataApi } from 'cp-xhr';
import { getPayOffProposalEndpoint, PayOffProposal } from '@cp-nl/common';
import { EarlySettlementLoadingPlaceholder } from '@cp-shared-7/frontend-ui';

const TotalEarlySettlementWithHandlers = withLoadingAndNoConnectionHandler(TotalEarlySettlementUi);

export const TotalEarlySettlement: React.FC<{ encryptedContractNumber: string }> = ({ encryptedContractNumber }) => {
    const { cmsContent: totalEarlySettlementCMS } = useTotalEarlySettlementCms();
    const { data: contract, isLoading, loadingError } = useContract(encryptedContractNumber, {
        encryptedContractNumber: true,
    });
    const [isGettingPayOffProposal, setIsGettingPayOffProposal] = useState(false);
    const [payOffProposalLoadingError, setPayOffProposalLoadingError] = useState(false);
    const [payOffProposal, setPayOffProposal] = useState<PayOffProposal>();

    useEffect(() => {
        setIsGettingPayOffProposal(true);
        CpDataApi.get(getPayOffProposalEndpoint(), { params: { contractId: encryptedContractNumber } })
            .then((response) => {
                setPayOffProposal(response.data);
                setPayOffProposalLoadingError(false);
            })
            .catch(() => {
                setPayOffProposalLoadingError(true);
            })
            .finally(() => {
                setIsGettingPayOffProposal(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TotalEarlySettlementWithHandlers
            totalEarlySettlementCMS={totalEarlySettlementCMS}
            payOffProposal={payOffProposal}
            isLoading={isLoading || isGettingPayOffProposal}
            contract={contract}
            hasError={!!loadingError || !!payOffProposalLoadingError}
            loadingPlaceholder={<EarlySettlementLoadingPlaceholder withDividedContractHeader summaryElements={3} />}
        />
    );
};
