export function autoLogoutPath(): string {
    return '/auto-logout';
}

export function notFoundPagePath(): string {
    return '/not-found';
}

export function registrationPagePath(): string {
    return '/register';
}

export function identificationPagePath(): string {
    return '/identify';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function forbiddenPagePath(): string {
    return '/forbidden';
}

export function connectionProblemPagePath(): string {
    return '/connection-problem';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function faqPagePath(): string {
    return '/faq';
}

export function cookiePolicyPath(): string {
    return '/cookie-policy';
}

export function legalNoticePath(): string {
    return '/legal-notice';
}

export function landingPagePath(): string {
    return '/';
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function changeContactDetailsPath(): string {
    return `${myProfilePagePath()}/change-contact-details`;
}

export function changeAddressPath(): string {
    return `${myProfilePagePath()}/change-address`;
}

export function myRequestPagePath(): string {
    return '/my-request';
}

export function myRequestPagePathWithContractId(contractNumber = ':contractNumber'): string {
    return `/my-request-unpaid-balance/${contractNumber}`;
}

export function postboxPagePath(): string {
    return '/postbox';
}

export function postboxPagePathWithContractId(contractNumber = ':contractNumber'): string {
    return `/contracts/${contractNumber}/postbox`;
}

export function loginInProgressPagePath(): string {
    return '/login-in-progress';
}

export function contractHandoverPagePath(contractNumber = ':contractNumber'): string {
    return `/contract/${contractNumber}/contract-handover`;
}

export function unpaidBalancePagePath(contractNumber = ':contractNumber'): string {
    return `/unpaid-balance/${contractNumber}`;
}

export function totalEarlySettlementPagePath(contractNumber = ':contractNumber'): string {
    return `/total-early-settlement/${contractNumber}`;
}

export function offlinePaymentPath(contractNumber = ':contractNumber'): string {
    return `${totalEarlySettlementPagePath(contractNumber)}/offline-payment`;
}

export function changeBankDetailsPath(): string {
    return `${myProfilePagePath()}/change-bank-details`;
}
