import { Contract, ContractErrorCode } from '@cp-nl/common';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-7/frontend-integration';
import { useMemo } from 'react';
import { ContractsData } from '../../../../common/build';
import { ContractsDataSelector } from '../contracts/ContractsDataSelector';
import { fetchContracts } from '../contracts/ContractsSlice';

type ContractTransformer = (contractsData?: ContractsData) => Contract | undefined;

const getFilterForContractId = (): ContractTransformer => {
    return (contractsData) => {
        const activeContracts = contractsData?.contracts?.filter((contract) => contract.contractStatus === 'ACTIVE');

        return activeContracts?.sort((a, b) => ((a.contractStartDate || '') > (b.contractStartDate || '') ? -1 : 1))[0];
    };
};

type ConfigProps = {
    encryptedContractNumber?: boolean;
};

/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractId Contract ID to get the contract for.
 */
export function useMostRecentContract(): AbstractDataState<Contract | undefined, ContractErrorCode> {
    const contractFilter = useMemo(() => getFilterForContractId(), []);
    return useGetSimpleApiDataWithTransformer(fetchContracts, ContractsDataSelector, contractFilter);
}
