import { Address } from '@cp-nl/common';
import { DataOverview, DefinitionList, DefinitionListItem } from '@cp-shared-7/frontend-ui';
import { Button } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditStatus, NotificationForEditStatus } from './NotificationForEditStatus';

export const AddressView: React.FC<{
    address?: Address;
    onEdit: (addressType: string) => void;
    addressType?: string;
    editStatus: EditStatus;
}> = ({ address, onEdit, editStatus, addressType }) => {
    const { t } = useTranslation(['my-profile', 'change-address']);

    const showVisit = address?.visitAddressCountry || address?.visitAddressLine1 || address?.visitAddressLine2;
    const showPostal = address?.postalAddressCountry || address?.postalAddressLine1 || address?.postalAddressLine2;
    const showButton = !showVisit && showPostal ? true : !!(showVisit && !showPostal);
    const addAddressButtonLabel = !showVisit
        ? t('change-address:add-button-label.visiting-address')
        : t('change-address:add-button-label.postal-address');

    const visitAddressItems: DefinitionListItem[] = [
        {
            label: t('address.visit-address-line1'),
            value: address?.visitAddressLine1,
        },
        {
            label: t('address.visit-address-line2'),
            value: address?.visitAddressLine2,
        },
        {
            label: t('address.visit-address-country'),
            value: address?.visitAddressCountry,
        },
    ].filter((item) => item.value);

    const postalAddressItems: DefinitionListItem[] = [
        {
            label: t('address.postal-address-line1'),
            value: address?.postalAddressLine1,
        },
        {
            label: t('address.postal-address-line2'),
            value: address?.postalAddressLine2,
        },
        {
            label: t('address.postal-address-country'),
            value: address?.postalAddressCountry,
        },
    ].filter((item) => item.value);

    return (
        <>
            {editStatus !== EditStatus.STARTED && <h3 className={'u-mt'}>{t('address.sub-headline')}</h3>}
            {showVisit && (
                <DataOverview
                    title={t('address.headline-personal-visiting-address')}
                    buttonLabel={t('change-address:edit-button-label')}
                    buttonProps={{ onClick: (): void => onEdit('Visit') }}
                >
                    <DefinitionList split={true} list={visitAddressItems} />
                    {addressType === 'Visit' && <NotificationForEditStatus editStatus={editStatus} />}
                </DataOverview>
            )}
            {showPostal && (
                <DataOverview
                    title={t('address.headline-personal-postal-address')}
                    buttonLabel={t('change-address:edit-button-label')}
                    buttonProps={{ onClick: (): void => onEdit('Postal') }}
                >
                    <DefinitionList split={true} list={postalAddressItems} />
                    {addressType === 'Postal' && <NotificationForEditStatus editStatus={editStatus} />}
                </DataOverview>
            )}
            {!showVisit && addressType === 'Visit' && editStatus !== 'NOT_PERFORMED' && (
                <DataOverview
                    title={t('address.headline-personal-visiting-address')}
                    buttonLabel={t('change-address:edit-button-label')}
                    buttonProps={{ onClick: (): void => onEdit('Visit') }}
                >
                    <NotificationForEditStatus editStatus={editStatus} />
                </DataOverview>
            )}
            {!showPostal && addressType === 'Postal' && editStatus !== 'NOT_PERFORMED' && (
                <DataOverview
                    title={t('address.headline-personal-postal-address')}
                    buttonLabel={t('change-address:edit-button-label')}
                    buttonProps={{ onClick: (): void => onEdit('Postal') }}
                >
                    <NotificationForEditStatus editStatus={editStatus} />
                </DataOverview>
            )}
            {showButton && editStatus !== EditStatus.STARTED && (
                <DataOverview>
                    <Button
                        link
                        small
                        className="u-pb-small"
                        icon="semantic-forward"
                        iconReversed
                        onClick={(): void => onEdit(!showVisit ? 'Visit' : 'Postal')}
                    >
                        {addAddressButtonLabel}
                    </Button>
                </DataOverview>
            )}
        </>
    );
};
