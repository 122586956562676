import { Brand } from '@cp-nl/common';
import { Stage } from '../utils';

const urls: Record<Stage, Record<Brand, string>> = {
    dev: {
        audi: 'https://audi.dev.cpnl.vwfs.io',
        seat: 'https://seat.dev.cpnl.vwfs.io',
        skoda: 'https://skoda.dev.cpnl.vwfs.io',
        vw: 'https://vw.dev.cpnl.vwfs.io',
        vwcv: 'https://vwcv.dev.cpnl.vwfs.io',
        df: 'https://df.dev.cpnl.vwfs.io',
    },
    int: {
        audi: 'https://audi.int.cpnl.vwfs.io',
        seat: 'https://seat.int.cpnl.vwfs.io',
        skoda: 'https://skoda.int.cpnl.vwfs.io',
        vw: 'https://vw.int.cpnl.vwfs.io',
        vwcv: 'https://vwcv.int.cpnl.vwfs.io',
        df: 'https://df.int.cpnl.vwfs.io',
    },
    cons: {
        audi: 'https://audi.cons.cpnl.vwfs.io',
        seat: 'https://seat.cons.cpnl.vwfs.io',
        skoda: 'https://skoda.cons.cpnl.vwfs.io',
        vw: 'https://vw.cons.cpnl.vwfs.io',
        vwcv: 'https://vwcv.cons.cpnl.vwfs.io',
        df: 'https://df.cons.cpnl.vwfs.io',
    },
    prod: {
        audi: 'https://mijnaudi.vwpfs.nl',
        seat: 'https://mijnseat.vwpfs.nl',
        skoda: 'https://mijnskoda.vwpfs.nl',
        vw: 'https://mijnvw.vwpfs.nl',
        vwcv: 'https://mijnvwbedrijfswagens.vwpfs.nl',
        df: 'https://mijndutchfinance.vwpfs.nl',
    },
};

export default urls;
