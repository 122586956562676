import React from 'react';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

import { IconFooter as IconFooterUi, IconFooterLoadingPlaceholder } from '@cp-shared-7/frontend-ui';
import { useIconFooter } from './useIconFooter';

const IconFooterWithHandlers = withLoadingAndNoConnectionHandler(IconFooterUi);

export const IconFooter: React.FC = () => {
    const { cmsContent: iconFooter, isLoading, loadingError } = useIconFooter();
    const { items, title } = iconFooter || {};

    const itemsText = (items || []).map((item) => ({
        ...item,
        text: <span key={item.text as string} dangerouslySetInnerHTML={{ __html: (item.text as string) || '' }} />,
    }));

    return (
        <IconFooterWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={title}
            items={itemsText}
            loadingPlaceholder={<IconFooterLoadingPlaceholder />}
        />
    );
};
