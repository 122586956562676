import { DashboardMarketingCard, getDashboardMarketingCardEndpoint } from '@cp-nl/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<DashboardMarketingCard>({
    contentName: 'dashboardMarketingCard',
    contentEndpoint: getDashboardMarketingCardEndpoint,
});

export default reducer;
export const fetchDashboardMarketingCard = fetchContent;
