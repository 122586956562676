import {
    CustomerVerificationError,
    getSmsSendingEndpoint,
    getVerifySmsEndpoint,
    SmsSendingError,
    SmsSendingInfo,
} from '@cp-nl/common';
import { Error, WithDefaultCpIntegrationErrors } from '@cp-shared-7/common-utilities';
import { parseErrorResponse } from '@cp-shared-7/frontend-integration';
import { CpDataApi } from 'cp-xhr';

export const handleSendSms = async (
    successCallback: (result: SmsSendingInfo) => void,
    errorCallback: (error: Error<WithDefaultCpIntegrationErrors<SmsSendingError>>) => void,
): Promise<void> => {
    try {
        const response = await CpDataApi.get(getSmsSendingEndpoint());
        successCallback(response.data);
    } catch (error) {
        errorCallback(parseErrorResponse<SmsSendingError>(error));
    }
};

export const handleSubmitForm = async (
    smsCode: string,
    successCallback: () => void,
    errorCallback: (error: Error<WithDefaultCpIntegrationErrors<CustomerVerificationError>>) => void,
): Promise<void> => {
    try {
        await CpDataApi.post(getVerifySmsEndpoint(), { smsCode });
        successCallback();
    } catch (error) {
        errorCallback(parseErrorResponse<CustomerVerificationError>(error));
    }
};
