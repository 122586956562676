import { Logo as BronsonLogo } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { currentBrand } from '../../config';

export const Logo: React.FC = () => {
    return currentBrand !== 'df' ? (
        <BronsonLogo className={'u-block-center'} />
    ) : (
        <img src="/contents/df/logo_DutchFinance.svg" className={'u-1/3 u-1/2@s u-1/1@xs'} alt="logo_DutchFinance" />
    );
};
