import { Contract, ContractHandover, ContractHandoverCms, getContractHandoverEndpoint } from '@cp-nl/common';
import {
    CleaveInput,
    preventSubmit,
    UiBlockingSpinner,
    ValidatedCheckbox,
    ValidatedInput,
    ValidatedSelectItem,
} from '@cp-shared-7/frontend-ui';
import {
    Button,
    ButtonContainer,
    DataOverview,
    Fieldset,
    Form,
    Layout,
    RadioButton,
    RadioButtonGroup,
    Select,
    FormField,
} from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ContractHandoverModal } from './ContractHandoverModal';
import { validationSchema } from './validationSchema';
import { countryCodeMapping } from '../../../../my-profile/contact-section/edit-view/country-code';

const optionsFromCountryMapping = (): ValidatedSelectItem[] => {
    return Object.entries(countryCodeMapping).map((value: [string, string[]]) => ({
        label: value[0],
        value: value[1][0],
    }));
};

export type ContractHandoverFormProps = {
    contract?: Contract;
    cmsContent?: ContractHandoverCms;
    onCancel: () => void;
};

export const ContractHandoverForm: React.FC<ContractHandoverFormProps> = ({ onCancel, cmsContent, contract }) => {
    const { t } = useTranslation('contract-handover');
    const history = useHistory();
    const { path } = useRouteMatch();

    const initialValues = {
        companyName: '',
        companyID: '',
        gender: 'Dhr.',
        initials: '',
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        mobileNumberCountryCode: '31',
        mobileNumber: '',
        consentFlag: false,
    };

    const [prefixMobilePhone, setPrefixMobilePhone] = useState(initialValues?.mobileNumberCountryCode);
    const [showSuccessDialogue, setShowSuccessDialogue] = useState(false);
    const [showErrorDialogue, setShowErrorDialogue] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handlerChangeInitialsInput = (
        e: ChangeEvent<HTMLInputElement>,
        setFieldValue: (name: string, value: string) => void,
    ): void => {
        const regex = /^([A-Z]\.)+$/;

        if (!regex.test(e.target.value)) {
            setFieldValue('initials', e.target.value.slice(0, -2));
        }
    };

    const handlerChangeAreaCodeMobilePhone = (
        event: ChangeEvent<HTMLInputElement>,
        setFieldValue: (name: string, value: string) => void,
    ): void => {
        setFieldValue('mobileNumberCountryCode', event.target.value);
        setPrefixMobilePhone(event.target.value);
    };

    const handleSubmit = (values: ContractHandover): void => {
        setIsSubmitting(true);
        CpDataApi.post(getContractHandoverEndpoint(contract?.encryptedContractVersionId || ''), values)
            .then(() => {
                setIsSubmitting(false);
                setShowSuccessDialogue(true);
            })
            .catch(() => {
                setShowErrorDialogue(true);
            });
    };

    const handleModalCancel = (): void => {
        history.push(path);
        setShowErrorDialogue(false);
    };

    const isProductTypeFL = () => contract?.productType?.includes('Financial Lease');

    if (!cmsContent || !contract) return null;

    return (
        <DataOverview title={cmsContent?.subheadline}>
            <UiBlockingSpinner isBlocking={isSubmitting}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema(t, prefixMobilePhone, contract?.productType || '')}
                >
                    {(formik) => (
                        <>
                            <Form onSubmit={preventSubmit()}>
                                <Fieldset>
                                    {isProductTypeFL() && (
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/3">
                                                    <ValidatedInput
                                                        label={cmsContent?.companyName}
                                                        name="companyName"
                                                        testId="companyName"
                                                        type="text"
                                                    />
                                                </Layout.Item>
                                                <Layout.Item default="1/3">
                                                    <ValidatedInput
                                                        label={cmsContent?.companyID}
                                                        name="companyID"
                                                        testId="companyID"
                                                        type="text"
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                    )}
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item>
                                                <p>{cmsContent?.gender}</p>
                                                <RadioButtonGroup>
                                                    <RadioButton
                                                        name="gender"
                                                        value="Dhr."
                                                        testId="maleGender"
                                                        defaultChecked={true}
                                                        onChange={() => formik.setFieldValue('gender', 'Dhr.')}
                                                    >
                                                        {cmsContent?.male}
                                                    </RadioButton>
                                                    <RadioButton
                                                        name="gender"
                                                        value="Mw."
                                                        testId="femaleGender"
                                                        onChange={() => formik.setFieldValue('gender', 'Mw.')}
                                                    >
                                                        {cmsContent?.female}
                                                    </RadioButton>
                                                </RadioButtonGroup>
                                            </Layout.Item>
                                            <Layout.Item default="1/3">
                                                <CleaveInput
                                                    label={cmsContent?.initials || ''}
                                                    name="initials"
                                                    testId="initials"
                                                    type="text"
                                                    cleaveOptions={{
                                                        delimiter: '.',
                                                        blocks: [1, 1, 1, 1, 1, 1],
                                                        numericOnly: false,
                                                        uppercase: true,
                                                    }}
                                                    handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                        handlerChangeInitialsInput(e, formik.setFieldValue)
                                                    }
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/3">
                                                <ValidatedInput
                                                    label={cmsContent?.firstName || ''}
                                                    name="firstName"
                                                    testId="firstName"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/3">
                                                <ValidatedInput
                                                    label={cmsContent?.middleName || ''}
                                                    name="middleName"
                                                    testId="middleName"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/3">
                                                <ValidatedInput
                                                    label={cmsContent?.lastName || ''}
                                                    name="lastName"
                                                    testId="lastName"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/3">
                                                <ValidatedInput
                                                    label={cmsContent?.email || ''}
                                                    name="email"
                                                    testId="email"
                                                    type="email"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/3">
                                                <FormField
                                                    type="select"
                                                    labelText={cmsContent?.mobileNumberCountryCode || ''}
                                                    testid="mobileNumberCountryCodeFormField"
                                                    name="mobileNumberCountryCode"
                                                >
                                                    <Select
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            handlerChangeAreaCodeMobilePhone(e, formik.setFieldValue)
                                                        }
                                                        id={prefixMobilePhone}
                                                        defaultValue={prefixMobilePhone}
                                                        testid="mobileNumberCountryCode"
                                                    >
                                                        {optionsFromCountryMapping().map((item, index) => {
                                                            return (
                                                                <Select.Item key={index} value={item.value}>
                                                                    {item.label}
                                                                </Select.Item>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormField>
                                            </Layout.Item>
                                            <Layout.Item default="1/3">
                                                <ValidatedInput
                                                    label={cmsContent?.mobileNumber || ''}
                                                    name="mobileNumber"
                                                    testId="mobileNumber"
                                                    type="tel"
                                                    addonText={`+${prefixMobilePhone}`}
                                                    reversed
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item>
                                                <ValidatedCheckbox
                                                    label={cmsContent?.consentFlag}
                                                    name="consentFlag"
                                                    testId="consentFlag"
                                                    isMandatory={true}
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ButtonContainer center>
                                            <Button secondary onClick={onCancel} testId="cancelButton">
                                                {cmsContent?.backButton}
                                            </Button>
                                            <Button onClick={formik.handleSubmit} type="submit" testId="submitButton">
                                                {cmsContent?.submitButton}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                            <ContractHandoverModal
                                showModal={showSuccessDialogue || showErrorDialogue}
                                status={showSuccessDialogue ? 'success' : 'error'}
                                closeDialog={showSuccessDialogue ? onCancel : handleModalCancel}
                                cmsContent={cmsContent}
                            />
                        </>
                    )}
                </Formik>
            </UiBlockingSpinner>
        </DataOverview>
    );
};
