export function getContractsEndpoint(): string {
    return '/contracts';
}

export function getContractsCountEndpoint(): string {
    return `${getContractsEndpoint()}/count`;
}

export function getInterveningPartiesEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/intervening-parties`;
}

export function getContractHandoverEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/handover`;
}

export function getPayOffProposalPdfEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/documents/payOffProposal`;
}
