import React from 'react';
import { Notification, NotificationStatus } from '@cp-shared-7/frontend-ui';
import { useAddressSection } from '../useAddressSection';

export enum EditStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    FOREIGN_CHANGE = 'FOREIGN_CHANGE',
    MANUAL_CHANGE = 'MANUAL_CHANGE',
    ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND',
    NOT_PERFORMED = 'NOT_PERFORMED',
    STARTED = 'STARTED',
}

export const NotificationForEditStatus: React.FC<{ editStatus: EditStatus; addressType?: string }> = ({
    editStatus,
}) => {
    const { cmsContent } = useAddressSection();

    switch (editStatus) {
        case EditStatus.SUCCESS: {
            return (
                <Notification status={NotificationStatus.success} testId={'editStatus'}>
                    {cmsContent?.addressChangeSuccessful}
                </Notification>
            );
        }
        case EditStatus.ERROR: {
            return (
                <Notification status={NotificationStatus.error} testId={'editStatus'}>
                    {cmsContent?.errServiceUnavailable}
                </Notification>
            );
        }
        case EditStatus.MANUAL_CHANGE: {
            return (
                <Notification status={NotificationStatus.warning} testId={'editStatus'}>
                    {cmsContent?.manualChange}
                </Notification>
            );
        }
        case EditStatus.FOREIGN_CHANGE: {
            return (
                <Notification status={NotificationStatus.warning} testId={'editStatus'}>
                    {cmsContent?.foreignChange}
                </Notification>
            );
        }
        case EditStatus.ADDRESS_NOT_FOUND: {
            return (
                <Notification
                    showCloseButton={false}
                    status={NotificationStatus.error}
                    testId="addressNotFoundNotification"
                >
                    {cmsContent?.errAddressNotFound}
                </Notification>
            );
        }
        default: {
            return null;
        }
    }
};
