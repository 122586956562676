import { RegistrationLoadingPlaceholder } from '@cp-shared-7/frontend-ui';
import { withLoadingHandler } from 'components/integration-wrapper';
import React from 'react';
import { RegistrationEmailUi } from './ui';
import { useRegistrationEmail } from './useRegistrationEmail';

const RegistrationEmailWithHandlers = withLoadingHandler(RegistrationEmailUi);

export const RegistrationEmail: React.FC = () => {
    const { cmsContent: registrationEmail, isLoading } = useRegistrationEmail();

    return (
        <RegistrationEmailWithHandlers
            isLoading={isLoading}
            registrationEmail={registrationEmail}
            loadingPlaceholder={<RegistrationLoadingPlaceholder />}
        />
    );
};
