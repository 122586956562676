import { TFunction } from 'i18next';
import * as Yup from 'yup';

const maxDoorNumberLength = 10;
const maxStreetAndLocalityLength = 50;

export const validationSchemaNL = (t: TFunction): Yup.ObjectSchema => {
    return Yup.object().shape({
        country: Yup.string(),
        postCode: Yup.string()
            .required(t('form.validation-error.post-code-required'))
            .matches(/^[0-9]{4}[ ][A-Z]{2}$/, t('form.validation-error.post-code-format-nl')),
        houseNumber: Yup.string().trim().required(t('form.validation-error.house-number-required')),
        door: Yup.string().trim().max(maxDoorNumberLength, t('form.validation-error.door-length')),
    });
};

export const validationSchema = (t: TFunction): Yup.ObjectSchema => {
    return Yup.object().shape({
        country: Yup.string(),
        postCode: Yup.string().required(t('form.validation-error.post-code-required')),
        houseNumber: Yup.string().trim().required(t('form.validation-error.house-number-required')),
        door: Yup.string().trim().max(maxDoorNumberLength, t('form.validation-error.door-length')),
        street: Yup.string()
            .trim()
            .required(t('form.validation-error.street-required'))
            .max(maxStreetAndLocalityLength, t('form.validation-error.street-length')),
        locality: Yup.string()
            .trim()
            .required(t('form.validation-error.locality-required'))
            .max(maxStreetAndLocalityLength, t('form.validation-error.locality-length')),
    });
};
