import { ContractErrorCode, ContractsData, getContractsEndpoint } from '@cp-nl/common';
import { getSimpleDataFetchSlice } from '@cp-shared-7/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<ContractsData, ContractErrorCode>({
    dataName: 'contracts',
    fetchCallback() {
        return CpDataApi.get(getContractsEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const fetchContracts = fetchData;
