import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { MyProfile } from 'components/my-profile/overview';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const MyProfilePage: React.FC = () => {
    const { t } = useTranslation('my-profile');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <MyProfile />
        </ContentSection>
    );
};
