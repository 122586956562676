import React, { FC } from 'react';
import { useRegistrationFlow } from '../../auth';
import { hasError, isLoading, notInitiated, RegistrationLoadingPlaceholder } from '@cp-shared-7/frontend-ui';
import {
    connectionProblemPagePath,
    dashboardPagePath,
    identificationPagePath,
} from '../../components/navigation/paths';
import { Redirect } from 'react-router-dom';

export const LoginInProgressPage: FC = () => {
    const { state: registrationState, initiate: initiateRegistration } = useRegistrationFlow();

    if (notInitiated(registrationState)) {
        initiateRegistration();
        return <RegistrationLoadingPlaceholder />;
    }

    if (hasError(registrationState)) {
        return <Redirect to={connectionProblemPagePath()} />;
    }

    if (isLoading(registrationState)) {
        return <RegistrationLoadingPlaceholder />;
    }

    if (!registrationState.isAuthorized) {
        return <Redirect to={identificationPagePath()} />;
    }

    return <Redirect to={dashboardPagePath()} />;
};
