import { ContractsCms, ErrorTypes } from '@cp-nl/common';
import {
    ContractsLoadingPlaceholder,
    NotificationStatus,
    useAnalyticsDashboardPageTracker,
} from '@cp-shared-7/frontend-ui';
import { loginInProgressPagePath } from 'components/navigation/paths';
import React from 'react';
import { useLastLocation } from 'react-router-last-location';
import { useCmsContent } from 'utils/useCmsContent';
import { ContractsOverview } from './ui';
import { useContracts } from './useContracs';
import { ErrorOf } from '../integration-wrapper/withCustomErrorNotification';
import { withLoadingAndCustomErrorNotificationHandler } from '../integration-wrapper/withLoadingAndCustomErrorNotificationHandler';

const ContractsOverviewWithHandlers = withLoadingAndCustomErrorNotificationHandler<ErrorOf<typeof useContracts>>()(
    ContractsOverview,
);

export const Contracts: React.FC = () => {
    const { data, isLoading, loadingError } = useContracts();
    const [cmsContent, isCmsLoading] = useCmsContent<ContractsCms>('contracts');
    const [errors, areErrorsLoading] = useCmsContent<ErrorTypes>('errors');

    const lastLocation = useLastLocation();
    const lastPathName = lastLocation === null ? loginInProgressPagePath() : lastLocation?.pathname;

    const unpaid = data && data?.contracts?.some((contract) => contract.unpaidBalance?.unpaidPresent);

    useAnalyticsDashboardPageTracker(!!data?.contracts?.length, !!unpaid, lastPathName || '');

    return (
        <ContractsOverviewWithHandlers
            mandatoryFieldsMissing={data?.isContractWithoutMandatoryFieldPresent}
            contracts={data?.contracts}
            content={cmsContent}
            isLoading={isLoading || isCmsLoading || areErrorsLoading}
            loadingPlaceholder={<ContractsLoadingPlaceholder numberOfContracts={data?.contracts?.length} />}
            error={loadingError}
            errorMap={{
                NO_CONTRACT_FOUND_FOR_GIVEN_BRAND: {
                    status: NotificationStatus.info,
                    title: cmsContent?.errors.noContracts.header,
                    text: cmsContent?.errors.noContracts.content,
                },
                MARKET_API_INTERNAL_SERVER_ERROR: {
                    status: NotificationStatus.error,
                    title: errors?.internalServerError.header,
                    text: errors?.internalServerError.content,
                },
                MARKET_API_SERVICE_UNAVAILABLE: {
                    status: NotificationStatus.error,
                    title: errors?.serviceUnavailable.header,
                    text: errors?.serviceUnavailable.content,
                },
                default: {
                    status: NotificationStatus.error,
                    title: errors?.default.header,
                    text: errors?.default.content,
                },
            }}
        />
    );
};
