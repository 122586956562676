import { HttpStatus } from './httpStatus';

export type InternalServerMarketApiError = 'MARKET_API_INTERNAL_SERVER_ERROR';

export type ServiceUnavailableMarketApiError = 'MARKET_API_SERVICE_UNAVAILABLE';

export type MarketApiErrorCode = InternalServerMarketApiError | ServiceUnavailableMarketApiError;

export type ErrorMapping = Partial<Record<HttpStatus, { code: MarketApiErrorCode; message: string }>>;

export const marketApiErrorMapping: ErrorMapping = {
    [HttpStatus.INTERNAL_SERVER_ERROR]: {
        code: 'MARKET_API_INTERNAL_SERVER_ERROR',
        message: 'The market API responded with an error indicating a technical problem',
    },
    [HttpStatus.SERVICE_UNAVAILABLE]: {
        code: 'MARKET_API_SERVICE_UNAVAILABLE',
        message: 'The market API is currently unavailable',
    },
};
