import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-7/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-7/frontend-storybook-extensions';
import { contractWithAllFields as Contracts } from 'components/contracts/ExampleData';
import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { ContractsMock } from '../contractsMock';
import { InterveningPartiesWithMultipleEntries } from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: InterveningPartiesWithMultipleEntries,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const InterveningPartiesMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Intervening Parties Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: Contracts._links?.interveningParties || '',
};

export const InterveningPartiesResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [ContractsMock, InterveningPartiesMock]);

    return <div>{storyFn()}</div>;
};
