import { SmsForm } from '@cp-nl/common';
import {
    preventSubmit,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    ValidatedCheckbox,
    ValidatedInput,
} from '@cp-shared-7/frontend-ui';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { identificationFormValidationSchema } from './validation';

export type FormProps = {
    smsCode: string;
    termsAndCondition: boolean;
    privacyPolicy: boolean;
};

type SmsIdentificationFormProps = {
    content: SmsForm;
    onSubmit: (values: FormProps) => void;
};

export const SmsIdentificationForm: React.FC<SmsIdentificationFormProps> = ({ content, onSubmit }) => {
    const [formValues, setformValues] = useState<FormProps>({
        smsCode: '',
        termsAndCondition: false,
        privacyPolicy: false,
    });

    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onStartTypingConfirmIdentity',
    });
    const { onAction: onValidationError } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');

    const getInitialErrors = (values: { [k: string]: string | boolean }) =>
        ['smsCode', 'privacyPolicy', 'termsAndCondition'].filter((element) => !values[element]).join(', ');

    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);
    const handleSubmit = (formData: FormProps): void => {
        setformValues({ ...formData });
        onSubmit(formData);
    };

    return (
        <>
            <Layout.Item>
                <Formik
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                    validationSchema={identificationFormValidationSchema(content.validation)}
                >
                    {(formik) => (
                        <Form onSubmit={preventSubmit()} onChange={() => onTyping(formik.errors, formik.touched)}>
                            <Fieldset>
                                <Fieldset.Row className="u-mb-small">
                                    <ValidatedInput
                                        label={content.inputFieldLabel}
                                        name="smsCode"
                                        tooltip={content.inputFieldTooltip}
                                        testId="sms-code"
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row className="u-mb-small">
                                    <ValidatedCheckbox
                                        label={<div dangerouslySetInnerHTML={{ __html: content.privacyPolicyLabel }} />}
                                        testId="privacy-policy-checkbox"
                                        name="privacyPolicy"
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row className="u-mb-small">
                                    <ValidatedCheckbox
                                        label={
                                            <div dangerouslySetInnerHTML={{ __html: content.termsAndConditionLabel }} />
                                        }
                                        testId="terms-and-condition-checkbox"
                                        name="termsAndCondition"
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row className="u-mt">
                                    <Button
                                        full={true}
                                        onClick={() => {
                                            formik.submitForm();
                                            const initialErrors = getInitialErrors({
                                                smsCode: formik.values.smsCode,
                                                privacyPolicy: formik.values.privacyPolicy,
                                                termsAndCondition: formik.values.termsAndCondition,
                                            });
                                            if (!isEmpty(formik.errors)) {
                                                const errorsList = getErrors(formik.errors);
                                                onValidationError(errorsList);
                                            } else if (!!initialErrors) {
                                                onValidationError(initialErrors);
                                            }
                                        }}
                                        testId={'submit-button'}
                                    >
                                        {content.submitLabel}
                                    </Button>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Layout.Item>
        </>
    );
};
