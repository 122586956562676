import React, { useState } from 'react';
import { Address, ChangeAddress } from '@cp-nl/common';
import { AddressView } from './address-view/AddressView';
import { AddressChange } from './address-change/AddressChange';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeAddressPath } from 'components/navigation/paths';
import { EditStatus } from './address-view/NotificationForEditStatus';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-7/frontend-ui';

export type EditStatusWithData = {
    status: EditStatus;
    addressData?: Address;
    formValues?: ChangeAddress;
};

export const AddressSection: React.FC<{ address?: Address }> = ({ address }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [addressType, setAddressType] = useState('');
    const [editStatus, setEditStatus] = useState<EditStatusWithData>({
        status: EditStatus.NOT_PERFORMED,
        addressData: address,
    });

    const { onAction: onEdit } = useAnalyticsActionTracker('onEditProfileAddress');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileAddressSubmitFailed',
        confirmSuccess: 'onEditProfileAddressSuccess',
    });

    const startEditing = (addressType: string): void => {
        onEdit();
        history.push(changeAddressPath());
        setAddressType(addressType);
        setEditStatus({
            ...editStatus,
            status: EditStatus.STARTED,
        });
    };

    const cancelEditing = (values?: ChangeAddress): void => {
        onCancel();
        history.push(path);
        setEditStatus({
            ...editStatus,
            status: EditStatus.NOT_PERFORMED,
            formValues: values,
        });
    };

    const finishEditing = (newEditStatus: EditStatus, updatedAddress?: Address): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onError();
        history.push(path);
        setEditStatus({
            ...editStatus,
            status: newEditStatus,
            addressData: newEditStatus === EditStatus.SUCCESS ? updatedAddress : address,
        });
    };

    const initialValues: ChangeAddress = {
        country: 'NL',
        postCode: '',
        houseNumber: '',
        door: '',
        street: '',
        locality: '',
        extraInfo: '',
    };

    if (!address) {
        return null;
    }

    return (
        <Switch>
            <Route exact path={changeAddressPath()}>
                <AddressChange
                    address={editStatus.addressData}
                    formValues={initialValues}
                    onCancel={cancelEditing}
                    finishEditing={finishEditing}
                    onEdit={startEditing}
                    addressType={addressType}
                    editStatus={editStatus.status}
                />
            </Route>
            <Route path={path}>
                <AddressView
                    address={editStatus.addressData}
                    onEdit={startEditing}
                    addressType={addressType}
                    editStatus={editStatus.status}
                />
            </Route>
        </Switch>
    );
};
