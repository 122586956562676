export enum FetchContentError {
    DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export type ContentEndpoint = (locale: string, brand: string) => string;

function getContentEndpoint(locale: string, brand: string, contentType: string): string {
    return `/${brand}/${locale}/${contentType}.json`;
}

export const getLegalFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-footer');
};

export const getLandingPageTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-teaser');
};

export const getIconFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'icon-footer');
};

export const getFaqPublicEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'faq');
};

export const getFaqPrivateEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'faq-private');
};

export const getMyProfileContentEndpoint: ContentEndpoint = (local, brand) => {
    return getContentEndpoint(local, brand, 'my-profile-content');
};

export const getRegisterPageEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'register-page');
};

export const getNavigationBarEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'navigation-bar');
};

export const getNoConnectionNotificationEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'no-connection-notification');
};

export const getMyRequestEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'my-request');
};

export const getDashboardMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'dashboard-marketing-card');
};

export const getContactSectionContentEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'contact-section-content');
};

export const getAddressSectionContentEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'address-section-content');
};

export const getTotalEarlySettlementContentEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'total-early-settlement');
};

export const getContactDetailsCheckEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'contact-details-check');
};

export const getOtherBrandsAvailableModal: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'other-brands-available-modal');
};
