import React from 'react';
import { Redirect } from 'react-router-dom';

import { createAuthenticationComponent, createAuthorization, createDecoratedRoute } from '@cp-shared-7/frontend-ui';

import { CenteredSpinner } from '../components/centered-spinner/CenteredSpinner';
import { connectionProblemPagePath, identificationPagePath, landingPagePath } from '../components/navigation/paths';
import { getUserRegistryStatusEndpoint, UserRegistryStatus } from '@cp-nl/common';
import { CpDataApi } from '../cp-xhr';

const Authentication = createAuthenticationComponent({
    onMissingAuthentication: <Redirect to={landingPagePath()} />,
});

const commonAuthorizationOptions = {
    onLoading: <CenteredSpinner />,
    onError: <Redirect to={connectionProblemPagePath()} />,
};

const { Authorization: UserRegistrationAuthorization, useAuthorizationFlow: useRegistrationFlow } = createAuthorization(
    {
        displayName: 'UserRegistrationAuthorization',
        authorizationDataProvider: async () => {
            const { data: registrationData } = await CpDataApi.get<UserRegistryStatus>(getUserRegistryStatusEndpoint());
            return { isAuthorized: registrationData.isRegistered };
        },
        onMissingAuthorization: <Redirect to={identificationPagePath()} />,
        ...commonAuthorizationOptions,
    },
);

const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', Authentication);
const AuthorizedRoute = createDecoratedRoute('AuthorizedRoute', Authentication, UserRegistrationAuthorization);

export { AuthenticatedRoute, AuthorizedRoute, useRegistrationFlow };
