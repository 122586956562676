import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-7/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-7/common-utilities';
import {
    VehicleDetailsWithAllValues,
    VehicleDetailsWithMissingValues,
    VehicleDetailsWithoutOneBodyColor,
    VehicleImageWithAllValues,
    VehicleImageWithMissingValues,
} from './ExampleData';
import { getVehicleImageServiceEndpoint, getVehicleServiceEndpoint } from '@cp-shared-7/apis';

const imageResponseMockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: VehicleImageWithAllValues,
        delay: 2000,
    },
    'With Missing Values': {
        status: 200,
        responseBody: VehicleImageWithMissingValues,
        delay: 2000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
        delay: 500,
    },
};

export const VehicleImageResponseMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Vehicle Image Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: imageResponseMockOptions,
    url: getVehicleImageServiceEndpoint('ENCRYPTED_WAUZZZ4G1FN076523'),
};

const detailsResponseMockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: VehicleDetailsWithAllValues,
        delay: 3000,
    },
    'With Missing Values': {
        status: 200,
        responseBody: VehicleDetailsWithMissingValues,
        delay: 2000,
    },
    'Without one body color': {
        status: 200,
        responseBody: VehicleDetailsWithoutOneBodyColor,
        delay: 2000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
        delay: 500,
    },
};

export const VehicleDetailsResponseMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Vehicle Details Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: detailsResponseMockOptions,
    url: getVehicleServiceEndpoint('ENCRYPTED_WAUZZZ4G1FN076523'),
};

export const VehicleDetailsAndImagesResponseMock = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [VehicleImageResponseMock, VehicleDetailsResponseMock]);

    return <div>{storyFn()}</div>;
};
