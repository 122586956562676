import React from 'react';
import { Error } from '@cp-shared-7/common-utilities';
import { getDisplayName } from '@cp-shared-7/frontend-ui';
import { AbstractDataState } from '@cp-shared-7/frontend-integration';
import { NotificationProps } from '../notifications/NotificationProps';
import { CustomErrorNotification } from '../notifications/CustomErrorNotification';

export type ErrorOf<T extends () => AbstractDataState<unknown, string>> = NonNullable<
    ReturnType<T>['loadingError']
>['code'];

export type withCustomErrorNotificationProps<TErrorCode extends string> = {
    error?: Error<TErrorCode>;
    errorMap: Partial<Record<TErrorCode, NotificationProps>> & { default: NotificationProps };
};

export const withCustomErrorNotification = <TErrorCode extends string>() => <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
): React.FC<TProps & withCustomErrorNotificationProps<TErrorCode>> => {
    const Wrapper: React.FC<TProps & withCustomErrorNotificationProps<TErrorCode>> = ({
        error,
        errorMap,
        ...props
    }) => {
        if (!error) {
            return <WrappedComponent {...(props as TProps)} />;
        }

        return <CustomErrorNotification {...(errorMap[error.code] ?? errorMap.default)} />;
    };

    Wrapper.displayName = `withCustomErrorNotification(${getDisplayName(WrappedComponent)})`;
    return Wrapper;
};
