import { Contract, getRequestEndpoint, MyRequest, OpenRequest } from '@cp-nl/common';
import {
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    ValidatedInput,
    ValidatedTextarea,
} from '@cp-shared-7/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CpDataApi } from '../../../cp-xhr';
import { dashboardPagePath } from '../../navigation/paths';
import { CategoryNumber } from './form/categories';
import { CategorySelect } from './form/CategorySelect';
import { ContractSelect } from './form/ContractSelect';
import { RequestFormFields } from './form/RequestFormFields';
import { RequestModal } from './form/RequestModal';
import { requestValidationSchema } from './form/requestValidationSchema';

type RequestUiProps = {
    myRequest?: MyRequest;
    contracts?: Contract[];
    unpaidBalanceContract?: string;
};

export const RequestUi: React.FC<RequestUiProps> = ({ myRequest, contracts, unpaidBalanceContract }) => {
    const history = useHistory();
    const { t } = useTranslation('request');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessDialogue, setShowSuccessDialogue] = useState(false);
    const [showErrorDialogue, setShowErrorDialogue] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');

    useAnalyticsPageViewTracker('requests', !!myRequest);

    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');
    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');

    const initialValues: RequestFormFields = {
        contract: '',
        category: unpaidBalanceContract ? '7' : '',
        title: '',
        message: '',
    };

    const redirectToDashboard = (): void => {
        history.push(dashboardPagePath());
    };

    const closeConfirmationDialogue = (): void => {
        setShowSuccessDialogue(false);
        setShowErrorDialogue(false);
    };

    const getContract = (contracts?: Contract[], contractNumber?: string): Contract | undefined => {
        if (contracts && contractNumber) {
            return contracts.filter((contract) => contract.contractNumber === contractNumber)[0];
        }
    };

    const getContractNumber = (contracts?: Contract[], contractEncryptedNumber?: string): string | undefined => {
        if (contracts && contractEncryptedNumber) {
            return contracts.filter((contract) => contract.contractNumber === contractEncryptedNumber)[0]
                .contractNumber;
        }
    };

    const handleSubmit = async (input: RequestFormFields): Promise<void> => {
        function handleError(): void {
            setIsSubmitting(false);
            setShowErrorDialogue(true);
        }

        const submitRequestData: OpenRequest = {
            category: input.category,
            contractNumber: getContract(contracts, input.contract)?.encryptedContractVersionId,
            requestTitle: input.title,
            requestText: input.message,
        };

        setSelectedCategory(submitRequestData.category);
        setIsSubmitting(true);

        CpDataApi.post(getRequestEndpoint(), submitRequestData)
            .then(() => {
                onSuccess(`Category: ${t(`category.text.${submitRequestData.category}`)}`);
                setIsSubmitting(false);
                setShowSuccessDialogue(true);
            })
            .catch(() => {
                onError(`Category: ${t(`category.text.${submitRequestData.category}`)}`);
                handleError();
            });
    };

    if (!myRequest) {
        return null;
    }

    return (
        <Layout>
            <Layout.Item>
                {myRequest.introText}
                <br />
            </Layout.Item>
            <Layout.Item>
                {isSubmitting && <Spinner fullPage={true} />}
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={requestValidationSchema(myRequest)}
                >
                    {(formik) => {
                        return (
                            <>
                                <Form onSubmit={preventSubmit()}>
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <ContractSelect
                                                label={myRequest.form.contract.label}
                                                contracts={contracts}
                                                name={'contract'}
                                                unpaidBalanceContract={getContractNumber(
                                                    contracts,
                                                    unpaidBalanceContract,
                                                )}
                                            />
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <CategorySelect
                                                label={myRequest.form.category.label}
                                                name={'category'}
                                                placeholder={myRequest.form.category.placeholder}
                                                isUnpaidBalance={!!unpaidBalanceContract}
                                            />
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <ValidatedInput
                                                name={'title'}
                                                id={'title'}
                                                testId={'title'}
                                                maxLength={40}
                                                label={myRequest.form.title.label}
                                            />
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <ValidatedTextarea
                                                name={'message'}
                                                id={'message'}
                                                testId={'message'}
                                                maxLength={1000}
                                                label={myRequest.form.message.label}
                                            />
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <ButtonContainer center>
                                                <Button
                                                    secondary
                                                    onClick={redirectToDashboard}
                                                    testId="dashboardButton"
                                                    type="btn"
                                                >
                                                    {myRequest.form.buttons.back}
                                                </Button>
                                                <Button
                                                    onClick={async () => {
                                                        await formik.validateForm();
                                                        formik.handleSubmit();
                                                    }}
                                                    testId="submitButton"
                                                    type="btn"
                                                >
                                                    {myRequest.form.buttons.send}
                                                </Button>
                                            </ButtonContainer>
                                        </Fieldset.Row>
                                    </Fieldset>
                                </Form>
                                <RequestModal
                                    categoryNumber={(selectedCategory as unknown) as CategoryNumber}
                                    myRequest={myRequest}
                                    showModal={showSuccessDialogue || showErrorDialogue}
                                    status={showErrorDialogue ? 'error' : showSuccessDialogue ? 'success' : ''}
                                    closeDialog={showErrorDialogue ? closeConfirmationDialogue : redirectToDashboard}
                                />
                            </>
                        );
                    }}
                </Formik>
            </Layout.Item>
        </Layout>
    );
};
