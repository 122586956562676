import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationStatus } from '@cp-shared-7/frontend-ui';
import { Notification } from '@vwfs-bronson/bronson-react';
import { EditStatus } from '../edit-view/EditView';
import { useContactSection } from '../useContactSection';

export const NotificationForEditStatus: React.FC<{ editStatus: EditStatus }> = ({ editStatus }) => {
    const { t } = useTranslation('contact-details-edit');
    const { cmsContent } = useContactSection();

    if (!cmsContent) {
        return null;
    }

    switch (editStatus) {
        case EditStatus.SUCCESS: {
            return (
                <Notification
                    status={NotificationStatus.success}
                    title={cmsContent?.changeSuccessfulTitle}
                    testId={'editStatus'}
                    visible
                    showCloseButton={false}
                >
                    {<span dangerouslySetInnerHTML={{ __html: cmsContent.changeSuccessfulText }} />}
                </Notification>
            );
        }
        case EditStatus.ERROR: {
            return (
                <Notification status={NotificationStatus.error} testId={'editStatus'} visible showCloseButton={false}>
                    {t('contact-details.change-error')}
                </Notification>
            );
        }
        default: {
            return null;
        }
    }
};
