import { MyRequest } from '@cp-nl/common';
import { Modal } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { CategoryNumber } from './categories';

type RequestModelProps = {
    categoryNumber: CategoryNumber;
    myRequest: MyRequest;
    showModal: boolean;
    status: string;
    closeDialog: () => void;
};

export const RequestModal: React.FC<RequestModelProps> = ({
    categoryNumber,
    myRequest,
    showModal,
    status,
    closeDialog,
}) => {
    let modalProps;
    let modalContent;

    if (status === 'success') {
        modalProps = {
            shown: showModal,
            status: status,
            buttonConfirmText: myRequest.form.modal.success.button,
            title: myRequest.form.modal.success.title[categoryNumber],
            onConfirm: closeDialog,
            onClickOutside: closeDialog,
            onClose: closeDialog,
            testId: 'confirmModal',
        };
        modalContent = myRequest.form.modal.success.text[categoryNumber];
    } else if (status === 'error') {
        modalProps = {
            shown: showModal,
            status: status,
            buttonConfirmText: myRequest.form.modal.error.button,
            title: myRequest.form.modal.error.title,
            onConfirm: closeDialog,
            onClickOutside: closeDialog,
            onClose: closeDialog,
            testId: 'errorModal',
        };
        modalContent = myRequest.form.modal.error.text;
    }

    return (
        <>
            <Modal {...modalProps}>{modalContent}</Modal>
        </>
    );
};
