import { ContentSection, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { Contracts } from 'components/contracts';
import { DashboardMarketingCard } from 'components/dashboard-marketing-card';
import { Greeting } from 'components/greeting';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OtherBrandsAvailableModal } from '../../components/other-brands-available-modal';

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation('dashboard');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading testId="dashboardHeading" level={1}>
                {t('headline')}
            </Heading>
            <Greeting />
            <Layout>
                <Layout.Item default={'2/3'} m={'1/1'}>
                    <Contracts />
                    <OtherBrandsAvailableModal />
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/1'}>
                    <DashboardMarketingCard />
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
