import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { usePostboxDocuments } from './usePostboxDocuments';
import { useContracts } from 'components/contracts/useContracs';
import { PostboxUi } from './ui';
import { useCmsContent } from 'utils/useCmsContent';
import { PostboxCms } from '@cp-nl/common';
import { MyDocumentsLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-7/frontend-ui';

const PostboxWithHandlers = withLoadingAndNoConnectionHandler(PostboxUi);
type PostboxProps = {
    defaultContractId?: string;
};

export const Postbox: React.FC<PostboxProps> = ({ defaultContractId }) => {
    const {
        data: postboxDocuments,
        isLoading: postboxDocumentsLoading,
        loadingError: postboxDocumentsLoadingError,
    } = usePostboxDocuments();
    const { data: contractsData, isLoading: contractsLoading } = useContracts();
    const [postboxCms, postboxCmsLoading, postboxCmsError] = useCmsContent<PostboxCms>('postbox');

    useAnalyticsPageViewTracker('postbox', !!postboxDocuments);

    return (
        <PostboxWithHandlers
            isLoading={postboxDocumentsLoading || contractsLoading || postboxCmsLoading}
            hasError={!!postboxCmsError}
            postboxDocuments={postboxDocuments || []}
            contracts={contractsData?.contracts || []}
            isDataFetchingError={!!postboxDocumentsLoadingError}
            postboxCms={postboxCms}
            defaultContractId={defaultContractId}
            loadingPlaceholder={<MyDocumentsLoadingPlaceholder />}
        />
    );
};
