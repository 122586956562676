import { BrokerDetails } from '@cp-nl/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-7/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-7/frontend-integration';
import { CpDataApi } from '../../cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<BrokerDetails, DefaultBusinessMarketApiErrorCode>({
    dataName: 'brokerDetails',
    fetchCallback(link: string) {
        return CpDataApi.get<BrokerDetails>(link).then((result) => result.data);
    },
});

export default reducer;
export const fetchBrokerDetails = fetchData;
