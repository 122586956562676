export function getMyProfileEndpoint(): string {
    return '/my-profile';
}

export function getChangeContactDetailsEndpoint(): string {
    return `${getMyProfileEndpoint()}/contactDetailsChange`;
}

export function getAddressDataEndpoint(): string {
    return `/get-address-data`;
}

export function getAddressChangeEndpoint(): string {
    return `${getMyProfileEndpoint()}/addressChange`;
}

export function getIbanChangeEndpoint(): string {
    return `${getMyProfileEndpoint()}/ibanChange`;
}
