export interface CategoryNumbers {
    '1': string;
    '2': string;
    '7': string;
    '5': string;
    '6': string;
}

export type CategoryNumber = keyof CategoryNumbers;

const categories: string[] = ['1', '2', '7', '5', '6'];

export const getRequestCategoryNumbers = (categories: string[]) => categories.map((item) => item);

export function getRequestCategories(): string[] {
    return getRequestCategoryNumbers(categories);
}
