import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Accordion, AccordionItem, Skeleton } from '@cp-shared-7/frontend-ui';
import { compact } from 'lodash';
import { ProductTable, Layout, DescriptionList } from '@vwfs-bronson/bronson-react';
import { VehicleServiceResponse } from '@cp-shared-7/apis';
import { getFuelTypeTranslation } from '../utils';
import { DefinitionTableItem } from '../types';

export const VehicleDetailsPlaceholder: React.FC = () => (
    <Layout>
        <Layout.Item>
            <Skeleton height={40} />
        </Layout.Item>
        <Layout.Item>
            <Skeleton height={40} />
        </Layout.Item>
    </Layout>
);

export const VehicleDetailsUi: React.FC<{
    details?: VehicleServiceResponse;
    isLoading: boolean;
}> = ({ details, isLoading }) => {
    const { t } = useTranslation('vehicle-details-and-image');
    const [accordionOpen, setAccordionOpen] = useState(false);

    const getVehicleDetails = useCallback((t: TFunction, details?: VehicleServiceResponse): DefinitionTableItem[] => {
        const { engineSize, enginePower, fuelType = '' } = details || {};
        return compact([
            engineSize && engineSize.value && engineSize.unit
                ? {
                      title: t('engine'),
                      value: `${engineSize.value} ${engineSize.unit} ${getFuelTypeTranslation(fuelType, t)}`,
                      testId: 'vehicle-details-engine-size',
                  }
                : undefined,
            enginePower && enginePower.value && enginePower.unit
                ? {
                      title: t('engine-power'),
                      value: `${enginePower.value} ${enginePower.unit}`,
                      testId: 'vehicle-details-engine-power',
                  }
                : undefined,
        ]);
    }, []);

    const additionalDetails: DefinitionTableItem[] = useMemo(() => getVehicleDetails(t, details), [
        getVehicleDetails,
        t,
        details,
    ]);

    const detailsComponent = useMemo(() => {
        return (
            <>
                {isLoading && <VehicleDetailsPlaceholder />}
                {!isLoading && (
                    <ProductTable.Section className={'u-mt-xsmall'}>
                        {additionalDetails.map((detail: DefinitionTableItem) => {
                            return (
                                <DescriptionList.Detail termText={detail.title} key={detail.title}>
                                    {detail.value}
                                </DescriptionList.Detail>
                            );
                        })}
                    </ProductTable.Section>
                )}
            </>
        );
    }, [isLoading, additionalDetails]);

    const handleOnChange = (refs: string[]): void => {
        if (refs.length) {
            setAccordionOpen(true);
        } else {
            setAccordionOpen(false);
        }
    };

    return (
        <>
            <Accordion className={'u-hide@s-up'} onChange={handleOnChange}>
                <AccordionItem
                    title={t(accordionOpen ? 'details-button.collapse' : 'details-button.expand')}
                    defaultOpen={accordionOpen}
                >
                    {detailsComponent}
                </AccordionItem>
            </Accordion>
            <div className={'u-hide@s'}>{detailsComponent}</div>
        </>
    );
};
