import { AuthenticationProvider, AutoLogout, ScrollToTopOnNavigation, Spinner } from '@cp-shared-7/frontend-ui';
import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { history } from './store/rootReducer';
import { MasterPage } from './pages';
import { LandingPage } from './pages/landing-page';
import { MyProfilePage } from './pages/my-profile-page';
import { useAxiosWithAuthentication, useAxiosWithCurrentLanguage } from './cp-xhr';
import {
    dashboardPagePath,
    faqPagePath,
    identificationPagePath,
    landingPagePath,
    loginInProgressPagePath,
    myProfilePagePath,
    myRequestPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    postboxPagePath,
    postboxPagePathWithContractId,
    registrationPagePath,
    forbiddenPagePath,
    contractHandoverPagePath,
    unpaidBalancePagePath,
    myRequestPagePathWithContractId,
    totalEarlySettlementPagePath,
    changeAddressPath,
    changeContactDetailsPath,
    changeBankDetailsPath,
} from './components/navigation/paths';
import { CenteredSpinner } from './components/centered-spinner';
import { AuthenticatedRoute, AuthorizedRoute } from './auth';
import { LoginInProgressPage } from './pages/login-in-progress-page';
import { RegistrationPage } from './pages/registration-page';
import { FaqPage } from 'pages/faq-page/FaqPage';
import { DashboardPage } from 'pages/dashboard-page';
import { MyRequestPage } from 'pages/my-request-page';
import { IdentificationPage } from 'pages/identification-page';
import { PostboxPage } from 'pages/postbox-page';
import { NotFoundPage } from 'pages/not-found-page';
import { NotAuthorizedPage } from 'pages/not-authorized-page';
import { ForbiddenPage } from 'pages/forbidden-page';
import { ContractHandoverPage } from 'pages/contract-handover-page';
import { UnpaidBalancePage } from 'pages/unpaid-balance-page';
import { TotalEarlySettlementPage } from 'pages/total-early-settlement-page';

const AxiosConfiguration: React.FC = ({ children }) => {
    useAxiosWithCurrentLanguage();
    useAxiosWithAuthentication();
    return <>{children}</>;
};

const scrollToTopExceptions = [
    { from: myProfilePagePath(), to: changeAddressPath() },
    { from: changeAddressPath(), to: myProfilePagePath() },
    { from: myProfilePagePath(), to: changeContactDetailsPath() },
    { from: changeContactDetailsPath(), to: myProfilePagePath() },
    { from: myProfilePagePath(), to: changeBankDetailsPath() },
    { from: changeBankDetailsPath(), to: myProfilePagePath() },
];

const App: React.FC = () => {
    return (
        <div className="App">
            <Suspense fallback={<Spinner center />}>
                <AuthenticationProvider loadingComponent={<CenteredSpinner />}>
                    <AutoLogout idleTimeInMinutes={5} redirectUri={window.location.origin + landingPagePath()}>
                        <AxiosConfiguration>
                            <ConnectedRouter history={history}>
                                <LastLocationProvider>
                                    <ScrollToTopOnNavigation exceptions={scrollToTopExceptions} />
                                    <MasterPage>
                                        <Switch>
                                            <Route exact path={landingPagePath()}>
                                                <LandingPage />
                                            </Route>
                                            <Route path={faqPagePath()}>
                                                <FaqPage />
                                            </Route>
                                            <AuthenticatedRoute
                                                path={loginInProgressPagePath()}
                                                component={LoginInProgressPage}
                                            />
                                            <AuthorizedRoute path={dashboardPagePath()}>
                                                <DashboardPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={myProfilePagePath()}>
                                                <MyProfilePage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={myRequestPagePath()}>
                                                <MyRequestPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={myRequestPagePathWithContractId()}>
                                                <MyRequestPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={postboxPagePath()}>
                                                <PostboxPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={postboxPagePathWithContractId()}>
                                                <PostboxPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={unpaidBalancePagePath()}>
                                                <UnpaidBalancePage />
                                            </AuthorizedRoute>
                                            <AuthenticatedRoute path={identificationPagePath()}>
                                                <IdentificationPage />
                                            </AuthenticatedRoute>
                                            <AuthenticatedRoute path={contractHandoverPagePath()}>
                                                <ContractHandoverPage />
                                            </AuthenticatedRoute>
                                            <AuthenticatedRoute path={totalEarlySettlementPagePath()}>
                                                <TotalEarlySettlementPage />
                                            </AuthenticatedRoute>
                                            <Route exact path={registrationPagePath()}>
                                                <RegistrationPage />
                                            </Route>
                                            <Route path={forbiddenPagePath()}>
                                                <ForbiddenPage />
                                            </Route>
                                            <Route path={notAuthorizedPagePath()}>
                                                <NotAuthorizedPage />
                                            </Route>
                                            <AuthorizedRoute path={notFoundPagePath()}>
                                                <NotFoundPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path="*">
                                                <Redirect to={notFoundPagePath()} />
                                            </AuthorizedRoute>
                                        </Switch>
                                    </MasterPage>
                                </LastLocationProvider>
                            </ConnectedRouter>
                        </AxiosConfiguration>
                    </AutoLogout>
                </AuthenticationProvider>
            </Suspense>
        </div>
    );
};

export default App;
