import React from 'react';

import { RootState } from 'store/rootReducer';
import { useCmsContent } from '@cp-shared-7/frontend-integration';
import { fetchFaq as fetchPublicFaq } from './FaqPublicSlice';
import { FaqWithHandlers } from './FaqView';
import { FaqLoadingPlaceholder } from '@cp-shared-7/frontend-ui';

export const FaqPublic: React.FC = () => {
    const { cmsContent: faq, isLoading, loadingError } = useCmsContent(fetchPublicFaq, (state: RootState) => {
        return state.content.faqPublic;
    });

    return (
        <FaqWithHandlers
            hasError={!!loadingError}
            isLoading={isLoading}
            faq={faq}
            loadingPlaceholder={<FaqLoadingPlaceholder withDescription />}
        />
    );
};
